import React from 'react';
import { Row, Col } from 'antd';
import PartnerItem from '../PartnerItem';
import './style.css';
import { useTranslation } from 'react-i18next';

import logoUhk from '../../assets/images/logo/logo-uhk.png';
import logoFim from '../../assets/images/logo/logo-fim.png';
import logoVsb from '../../assets/images/logo/logo-vsb.png';
import logoTacr from '../../assets/images/logo/logo-tacr.png';

export default function PartnersContainer() {
  const { t } = useTranslation();

  const partners = [
    {
      name: t('contact.uhk'),
      imageSrc: logoUhk,
      link: 'https://www.uhk.cz/',
    },
    {
      name: `${t('contact.uhk')}\n${t('contact.fim')}`,
      imageSrc: logoFim,
      link: 'https://www.uhk.cz/cs/fakulta-informatiky-a-managementu/fim',
    },
    {
      name: `${t('contact.vsb-tuo')}\n${t('contact.fei')}`,
      imageSrc: logoVsb,
      link: 'https://www.vsb.cz/cs/',
    },
    {
      name: t('contact.tacr'),
      imageSrc: logoTacr,
      link: 'https://www.tacr.cz/',
    },
  ];

  return (
    <Row justify="space-around" className="Container">
      {partners.map((partner, idx) => (
        <Col md={6} sm={12} xs={24} key={idx}>
          <PartnerItem partner={partner} />
        </Col>
      ))}
    </Row>
  );
}
