import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

export default function Content({ children }) {
  return (
    <Row justify="center">
      <S.Col md={16} sm={20} xs={24}>
        {children}
      </S.Col>
    </Row>
  );
}

const S = {};

S.Col = styled(Col)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 400px;
`;
