import React from 'react';
import Layout from '../../components/Layout';
import PartnersContainer from '../../components/PartnersContainer';
import { Row, Col, Typography } from 'antd';
import Container from '../../components/Container';
import styled from 'styled-components';
import Content from '../../components/Content';
import { useTranslation } from 'react-i18next';

import modelImageCs from '../../assets/images/model_about_cs.jpg';
import modelImageEn from '../../assets/images/model_about_en.jpg';

const { Title, Text } = Typography;

export default function CalculationPage() {
  const { t, i18n } = useTranslation();
  return (
    <Layout>
      <Container>
        <Content>
          <S.TitleContainer justify="center">
            <Col md={16} sm={20} xs={24}>
              <S.Title level={2}>{t('calculation.title')}</S.Title>
            </Col>
          </S.TitleContainer>
          <S.FormattedParagraph
            dangerouslySetInnerHTML={{
              __html: t('calculation.description', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
          <S.Blockquote>{t('calculation.call-to-action')}</S.Blockquote>

          <S.SubTitleContainer>
            <S.Title level={3}>{t('calculation-basis.title')}</S.Title>
          </S.SubTitleContainer>
          <S.Paragraph>{t('calculation-basis.p1')}</S.Paragraph>
          <S.Paragraph>{t('calculation-basis.p2')}</S.Paragraph>
          <S.Paragraph>{t('calculation-basis.p3')}</S.Paragraph>
          <S.ImageContainer>
            <S.Image
              src={i18n.language === 'cs' ? modelImageCs : modelImageEn}
              alt="med cal model"
            />
            <S.ImageCaption>{t('calculation-basis.fig1')}</S.ImageCaption>
          </S.ImageContainer>

          <S.Paragraph>{t('calculation-basis.p4')}</S.Paragraph>
        </Content>
      </Container>

      <PartnersContainer />
    </Layout>
  );
}

const S = {};

S.TitleContainer = styled(Row)`
  padding: 0 60px;
  text-align: center;
`;

S.SubTitleContainer = styled(Row)`
  padding: 0 60px;
  text-align: center;
  margin-top: 45px;
`;

S.Title = styled(Title)`
  && {
    font-weight: 900;
  }
`;

S.SubTitle = styled(Title)`
  && {
    font-weight: 700;
    margin-top: -20px;
    color: #666;
  }
`;

S.FormattedParagraph = styled.div`
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  margin: 24px 0 0;
  padding: 0 60px;
`;

S.Paragraph = styled(Text)`
  && {
    text-align: justify;
    line-height: 1.5;
    font-size: 16px;
    margin: 24px 0 0;
    padding: 0 60px;
  }
`;

S.Blockquote = styled(Text)`
  && {
    margin: 18px 0 0;
    padding: 0 100px;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
  }
`;

S.ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 25px auto 0;
`;

S.Image = styled.img`
  width: 75%;
`;

S.ImageCaption = styled.div`
  font-size: 12px;
  color: #888;
  margin: 16px auto;
`;
