import React from 'react';
import Layout from '../../components/Layout';
import Content from '../../components/Content';
import Container from '../../components/Container';
import PartnersContainer from '../../components/PartnersContainer';
import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const SurveyDashboardPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Container>
        <Content>
          <S.TitleContainer justify="center">
            <Col md={16} sm={20} xs={24}>
              <S.Title level={2}>{t('calculation-variants.title')}</S.Title>
            </Col>
          </S.TitleContainer>
          <Row justify="center">
            <S.Col sm={10} xs={24}>
              <Link to="/calculations/startup">
                <Title level={5}>{t('startup.tag')}</Title>
                <S.Paragraph>
                  {t('calculation-variants.startup-p1')}
                </S.Paragraph>
                <S.Paragraph>
                  <ul>
                    <li>{t('calculation-variants.startup-p1-list.l1')}</li>
                    <li>{t('calculation-variants.startup-p1-list.l2')}</li>
                  </ul>
                </S.Paragraph>
                <S.Paragraph>
                  {t('calculation-variants.startup-p2')}
                </S.Paragraph>
              </Link>
            </S.Col>
            <S.Col sm={10} xs={24}>
              <Link to="/calculations/established">
                <Title level={5}>{t('estco.tag')}</Title>
                <S.Paragraph>{t('calculation-variants.estco-p1')}</S.Paragraph>
                <S.Paragraph>
                  <ul>
                    <li>{t('calculation-variants.estco-p1-list.l1')}</li>
                    <li>{t('calculation-variants.estco-p1-list.l2')}</li>
                    <li>{t('calculation-variants.estco-p1-list.l3')}</li>
                  </ul>
                </S.Paragraph>
              </Link>
            </S.Col>
          </Row>
        </Content>
      </Container>

      <PartnersContainer />
    </Layout>
  );
};

export default SurveyDashboardPage;

const S = {};

S.TitleContainer = styled(Row)`
  padding: 0 60px;
  text-align: center;
`;

S.Title = styled(Title)`
  && {
    font-weight: 900;
  }
`;

S.SubTitle = styled(Title)`
  && {
    font-weight: 700;
    margin-top: -20px;
    color: #666;
  }
`;

S.Paragraph = styled(Text)`
  && {
    line-height: 1.5;
    font-size: 16px;
    margin: 24px 0 0;
  }
`;

S.Col = styled(Col)`
  border: 1px solid transparent;
  margin: 0 25px;
  padding: 15px;

  &&:hover {
    border: 1px solid #1890ff;
    border-radius: 10px;
  }
`;

S.Blockquote = styled(Text)`
  && {
    margin: 18px 0 0;
    padding: 0 100px;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
  }
`;
