export const PASSWORD_CHANGED = 100;
export const PASSWORD_VERIFIED = 101;
export const INVALID_CREDENTIALS = 102;
export const VERIFICATION_FAILED = 103;
export const USER_NOT_FOUND = 104;
export const EMAIL_ALREADY_USED = 105;
export const SURVEY_NOT_FOUND = 106;
export const EMAIL_AVAILABLE = 107;
export const RESET_LINK_SENT = 120;
export const RESET_LINK_NOT_SENT = 125;
export const INVALID_REQUEST = 130;
export const BAD_REQUEST = 400;
