import React, { useContext, useState } from 'react';
import AuthContext from '../../state/AuthContext';
import StorageHandler, {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  STORAGE_USER,
} from '../../utils/storage.handler';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import PartnersContainer from '../../components/PartnersContainer';
import { Row, Col, Typography, Form, Card, Button, Input } from 'antd';
import Container from '../../components/Container';
import styled from 'styled-components';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import apiReq from '../../utils/api.request';
import handleMessage from '../../utils/message.handler';
import roleUtils, { ADMIN, USER } from '../../utils/role.utils';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const LoginPage = () => {
  const { t } = useTranslation();
  const Auth = useContext(AuthContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [signInError, setSignInError] = useState(null);

  const handleSubmit = async form => {
    const signInReq = await apiReq.signInRequest(form);
    const {
      payload: signInReqPayload,
      error: signInReqError,
      meta: signInReqMeta,
    } = signInReq.data;
    console.log({ signInReqPayload, signInReqError, signInReqMeta });
    if (signInReqError) {
      setSignInError(signInReqMeta.opsStatus);
    } else {
      if (signInReqPayload) {
        await StorageHandler.save(
          ACCESS_TOKEN,
          signInReqPayload.data.accessToken,
        );
        await StorageHandler.save(
          REFRESH_TOKEN,
          signInReqPayload.data.refreshToken,
        );
      }
      setSignInError(null);
      const getMeReq = await apiReq.getMeRequest(
        await StorageHandler.load(ACCESS_TOKEN),
      );
      const { payload: getMeReqPayload } = getMeReq.data;
      if (getMeReqPayload) {
        const { id, fullName, email, company, roles } = getMeReqPayload.data;
        let role;
        if (roles.length !== 0) {
          if (roles.includes(ADMIN)) {
            role = ADMIN;
          } else {
            role = USER;
          }
        } else {
          role = null;
        }
        StorageHandler.store(
          STORAGE_USER,
          JSON.stringify({
            id,
            fullName,
            email,
            company,
            token: signInReqPayload.data.token,
            role: roleUtils.encodeRole(role),
          }),
        );
        Auth.setAuth(true);
        history.replace('/calculations');
      }
    }
  };

  return (
    <Layout>
      <Container>
        <S.Row>
          <Col md={6} sm={12} xs={24}>
            <S.Card>
              <S.Title level={2}>{t('common.menu.signin')}</S.Title>
              <S.Form
                name="login"
                onFinish={handleSubmit}
                form={form}
                wrapperCol={{ span: 20, offset: 2 }}
                size="large"
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-login-email'),
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t('common.forms.email')}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-login-password'),
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t('common.forms.password')}
                  />
                </Form.Item>

                {signInError && (
                  <S.ErrorMessage>
                    {t(`common.validation.${handleMessage(signInError)}`)}
                  </S.ErrorMessage>
                )}

                <S.FormFooter>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    {t('common.buttons.signin')}
                  </Button>
                  <S.AdditNavRow>
                    <Col sm={10} xs={24} style={{ textAlign: 'center' }}>
                      <Link to="/forgot-password">
                        {t('common.forms.forgot-password')}
                      </Link>
                    </Col>
                    <Col
                      sm={{ span: 10, offset: 4 }}
                      xs={24}
                      style={{ textAlign: 'center' }}
                    >
                      <Link to="/register">
                        {t('common.forms.create-account')}
                      </Link>
                    </Col>
                  </S.AdditNavRow>
                </S.FormFooter>
              </S.Form>
            </S.Card>
          </Col>
        </S.Row>
      </Container>
      <PartnersContainer />
    </Layout>
  );
};

export default LoginPage;

const S = {};

S.TitleContainer = styled.div`
  width: 60vw;
  margin: 0 auto;
  text-align: center;
`;

S.Title = styled(Title)`
  && {
    margin: 0 0 25px;
    font-weight: 900;
    text-align: center;
  }
`;

S.SubTitle = styled(Title)`
  && {
    font-weight: 700;
    margin-top: -20px;
    color: #666;
  }
`;

S.Row = styled(Row)`
  justify-content: center;
`;

S.Card = styled(Card)`
  border: 1px solid #ccc;
  border-radius: 5px;
`;

S.AvatarImg = styled.img`
  height: 75px;
  width: 75px;
`;

S.Form = styled(Form)`
  display: flex;
  flex-direction: column;
`;

S.FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.AdditNavRow = styled(Row)`
  margin-top: 25px;
`;

S.ErrorMessage = styled(Text)`
  text-align: center;
  font-size: 14px;
  color: red;
  margin-bottom: 15px;
`;
