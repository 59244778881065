import React from 'react';
import Layout from '../../components/Layout';
import PartnersContainer from '../../components/PartnersContainer';
import { Row, Col, Typography } from 'antd';
import Container from '../../components/Container';
import styled from 'styled-components';
import Content from '../../components/Content';
import { useTranslation } from 'react-i18next';

const { Title, Text, Link } = Typography;

export default function ContactPage() {
  const { t } = useTranslation();
  return (
    <Layout>
      <Container>
        <Content>
          <S.TitleContainer>
            <S.Title level={2}>{t('contact.title')}</S.Title>
            <S.SubTitle level={3}>{t('contact.subtitle')}</S.SubTitle>
          </S.TitleContainer>
          <S.Blockquote>
            {`${t('contact.uhk')} - ${t('contact.fim')}`}
          </S.Blockquote>
          <S.Row>
            <Col md={12} sm={24}>
              <S.Paragraph>
                <Text type="primary">prof. Ing. Kamil Kuča, Ph.D.</Text>
                <Text type="primary">doc. Ing. Mgr. Petra Marešová, Ph.D.</Text>
                <Text type="primary">doc. Ing. Vladimír Soběslav, Ph.D.</Text>
                <Text type="primary">prof. Ing. Ondřej Krejcar, Ph.D.</Text>
              </S.Paragraph>
            </Col>
            <Col md={12} sm={24}>
              <S.Card src="https://www.uhk.cz/file/edee/foto_pr_uhk/budovy/j.jpg">
                <S.Contact>
                  <S.AddressSchoolTitle>
                    {t('contact.uhk')}
                  </S.AddressSchoolTitle>
                  <S.AddressFacultyTitle>
                    {t('contact.fim')}
                  </S.AddressFacultyTitle>
                  <S.Text>{t('contact.uhk-address-number')}</S.Text>
                  <S.Text>{t('contact.uhk-address-city')}</S.Text>
                  <S.Link href={`mailto:${t('contact.uhk-contact')}`}>
                    {t('contact.uhk-contact')}
                  </S.Link>
                </S.Contact>
                {/* <S.Image
                  src="https://www.uhk.cz/file/edee/foto_pr_uhk/budovy/j.jpg"
                  alt="fim uhk"
                /> */}
              </S.Card>
            </Col>
          </S.Row>

          <S.Blockquote>
            Vysoká škola báňská - Technická univerzita Ostrava
          </S.Blockquote>
          <S.Row>
            <Col md={12} sm={24}>
              <S.Paragraph>
                <Text type="primary">prof. Ing. Marek Penhaker, Ph.D.</Text>
                <Text type="primary">Ing. Martin Černý, Ph.D.</Text>
                <Text type="primary">Ing. Lukáš Peter, Ph.D.</Text>
              </S.Paragraph>
            </Col>
            <Col md={12} sm={24}>
              <S.Card src="https://www.vsb.cz/cs/.content/galerie-obrazku/rektorat.jpg">
                <S.Contact>
                  <S.AddressSchoolTitle>
                    {t('contact.vsb-tuo')}
                  </S.AddressSchoolTitle>
                  <S.AddressFacultyTitle>
                    {t('contact.fei')}
                  </S.AddressFacultyTitle>
                  <S.Text>{t('contact.vsb-address-number')}</S.Text>
                  <S.Text>{t('contact.vsb-address-city')}</S.Text>
                  <S.Link href={`mailto:${t('contact.vsb-contact')}`}>
                    {t('contact.vsb-contact')}
                  </S.Link>
                </S.Contact>
                {/* <S.Image
                  src="https://www.vsb.cz/cs/.content/galerie-obrazku/rektorat.jpg"
                  alt="vsb"
                /> */}
              </S.Card>
            </Col>
          </S.Row>
          <S.Blockquote>{t('contact.acknowledgement')}</S.Blockquote>
        </Content>
      </Container>
      <PartnersContainer />
    </Layout>
  );
}

const S = {};

S.TitleContainer = styled.div`
  width: 60vw;
  margin: 0 auto;
  text-align: center;
`;

S.Title = styled(Title)`
  && {
    font-weight: 900;
  }
`;

S.SubTitle = styled(Title)`
  && {
    font-weight: 700;
    margin-top: -20px;
    color: #666;
  }
`;

S.Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  font-size: 16px;
  margin: 25px 15px 25px 0;
`;

S.Row = styled(Row)`
  padding: 25px 60px 0;
`;

S.Blockquote = styled(Text)`
  && {
    margin: 18px 0 0;
    padding: 0 100px;
    font-size: 21px;
    font-weight: 300;
    text-align: center;
  }
`;

S.Card = styled.div`
  background: url(${props => props.src}) center center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 10px;
`;

S.Image = styled.img`
  background-image: ${props => props.src};
  background-size: contain;
  background-repeat: no-repeat;
  width: 25%;
  border-radius: 0 9px 9px 0;
`;

S.Contact = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  width: 100%;
  flex-direction: column;
  padding: 15px;
`;

S.AddressSchoolTitle = styled(Text)`
  && {
    font-size: 16px;
    font-weight: 400;
    color: #ccc;
  }
`;

S.AddressFacultyTitle = styled(Text)`
  && {
    font-size: 20px;
    font-weight: 600;
    color: white;
  }
`;

S.Text = styled(Text)`
  && {
    color: white;
  }
`;

S.Link = styled(Link)`
  && {
    color: #1cb7ff;
  }
`;
