import encodeUuid from 'uuid-by-string';

export const USER = 'ROLE_USER';
export const ADMIN = 'ROLE_ADMIN';
export const NONE = 'NONE';

const roles = [
  {
    role: USER,
    key: encodeUuid(`role::${USER}`),
  },
  {
    role: ADMIN,
    key: encodeUuid(`role::${ADMIN}`),
  },
];

const encodeRole = role => {
  return encodeUuid(`role::${role}`);
};

const getRole = hashedKey => {
  switch (hashedKey) {
    case roles[0].key:
      return USER;
    case roles[1].key:
      return ADMIN;
    default:
      return NONE;
  }
};

export default {
  encodeRole,
  getRole,
};
