import React from 'react';
import styled from 'styled-components';

export default function Container({ children }) {
  return <S.Container>{children}</S.Container>;
}

const S = {};

S.Container = styled.div`
  flex: 1;
  margin: 50px 0;
  padding: 50px auto;
`;
