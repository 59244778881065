import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import PartnersContainer from '../../components/PartnersContainer';
import { Row, Col, Typography, Carousel } from 'antd';
import Container from '../../components/Container';
import styled from 'styled-components';
import Content from '../../components/Content';

import medical01 from '../../assets/images/carousel/medical_01.jpg';
import medical02 from '../../assets/images/carousel/medical_02.jpg';
import medical03 from '../../assets/images/carousel/medical_03.jpg';
import medical04 from '../../assets/images/carousel/medical_04.jpg';
import medical05 from '../../assets/images/carousel/medical_05.jpg';

const images = [medical01, medical02, medical03, medical04, medical05];

const { Title, Text } = Typography;

const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container>
        <S.Carousel autoplay effect="fade">
          {images.map((image, idx) => (
            <S.ContainerBackground src={image} key={idx}>
              <S.TitleContainer justify="center">
                <Col md={16} sm={20} xs={24}>
                  {/* <S.Title>Náklady na vývoj zdravotních prostředků</S.Title> */}
                  <S.Title>{t('introduction.title')}</S.Title>
                  <S.SubTitle level={3}>
                    {t('introduction.subtitle')}
                  </S.SubTitle>
                </Col>
              </S.TitleContainer>
            </S.ContainerBackground>
          ))}
        </S.Carousel>

        <Content>
          <S.Blockquote>{t('introduction.welcome')}</S.Blockquote>
          <S.Paragraph>{t('introduction.p1')}</S.Paragraph>
          <S.Paragraph>{t('introduction.p2')}</S.Paragraph>
          <S.Paragraph>
            {t('introduction.p3')}
            <ul>
              <li>{t('introduction.p3-list.l1')}</li>
              <li>{t('introduction.p3-list.l2')}</li>
              <li>{t('introduction.p3-list.l3')}</li>
            </ul>
          </S.Paragraph>
        </Content>
      </Container>
      <PartnersContainer />
    </Layout>
  );
};

export default IndexPage;

const S = {};

S.Col = styled(Col)`
  display: flex;
  flex-direction: column;
`;

S.Carousel = styled(Carousel)`
  justify-content: center;
  align-content: center;
  margin: -50px 0 25px;
  width: 100%;
  height: 450px;
`;

S.TitleContainer = styled(Row)`
  padding: 0 60px;
  width: 100%;
  align-items: center;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
`;

S.ContainerBackground = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 450px;
`;

S.Title = styled(Title)`
  && {
    color: #fff;
    font-weight: 900;
  }
`;

S.SubTitle = styled(Title)`
  && {
    font-weight: 700;
    margin-top: -20px;
    color: #ccc;
  }
`;

S.Paragraph = styled(Text)`
  && {
    text-align: justify;
    line-height: 1.5;
    font-size: 16px;
    margin: 25px 0 0;
    padding: 0 60px;
  }
`;

S.Blockquote = styled(Text)`
  && {
    margin: 25px 0 0;
    padding: 0 100px;
    font-size: 21px;
    font-weight: 300;
    text-align: center;
  }
`;
