import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import stringsCS from '../assets/locales/cs/strings.json';
import stringsEN from '../assets/locales/en/strings.json';

// the translations
const resources = {
  cs: {
    translation: stringsCS,
  },
  en: {
    translation: stringsEN,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'cs',
    fallbackLng: 'cs', // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
