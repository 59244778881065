import React from 'react';
import './style.css';

export default function PartnerItem({ partner }) {
  return (
    <div className="Wrapper">
      <a href={partner.link} target="_blank" rel="noopener noreferrer">
        <img src={partner.imageSrc} alt={partner.name} />
      </a>
      <div>{partner.name}</div>
    </div>
  );
}
