import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedAuth = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (!auth ? <Component /> : <Redirect to="/calculations" />)}
    />
  );
};

export default ProtectedAuth;
