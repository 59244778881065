import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Content from '../../../components/Content';
import Container from '../../../components/Container';
import styled from 'styled-components';
import { Row, Col, Typography, Table, Tag, Space, Button, Modal } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import StorageHandler, { STORAGE_USER } from '../../../utils/storage.handler';
import apiReq from '../../../utils/api.request';
import {
  SURVEY_STARTUP,
  SURVEY_ESTABLISHED,
  EXPORT_STARTUP,
  EXPORT_ESTABLISHED,
} from '../../../utils/constants';
import roleUtils, { ADMIN, USER } from '../../../utils/role.utils';

const { Title, Text } = Typography;

const FilledSurveys = () => {
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(USER);
  const [calculations, setCalculations] = useState([]);

  const handlePdfGeneration = async item => {
    const data = {
      userId: user.id,
      surveyId: item.id,
      surveyTypeId:
        item.type === SURVEY_STARTUP.type
          ? SURVEY_STARTUP.typeId
          : SURVEY_ESTABLISHED.typeId,
    };

    const response = await apiReq.sendPdfGenerationRequest(
      user.token,
      data,
      i18n.language,
    );
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const handleCsvExport = async type => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}${
      date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    }${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}${
      date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    }${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
    const filename = `export_${
      type === EXPORT_STARTUP ? 'startup' : 'established'
    }_${formattedDate}.csv`;
    const response = await apiReq.getCSVExportRequest(type, user.token);
    console.log({ response });

    const file = new Blob([response.data], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    const loadUser = async () => {
      const u = await StorageHandler.parse(STORAGE_USER);
      setUser(u);
      setRole(await roleUtils.getRole(u.role));
      if (u) {
        const response = await apiReq.getAllCalculations(u.token);
        const { payload } = response.data;
        if (payload) {
          const data = [];
          payload.data.forEach((item, idx) => {
            const date = new Date(item.createdAt);
            const parsedDate = `${
              date.getDate() < 10 ? '0' : ''
            }${date.getDate()}.${date.getMonth() < 9 ? '0' : ''}${
              date.getMonth() + 1
            }.${date.getFullYear()} ${
              date.getHours() < 10 ? '0' : ''
            }${date.getHours()}:${
              date.getMinutes() < 10 ? '0' : ''
            }${date.getMinutes()}`;
            data.push({
              key: `${idx}_${item.createdAt}`,
              no: idx + 1,
              id: item.id,
              datetime: parsedDate,
              type: item.surveyName,
              action: item,
              user: item.userFullName,
              company: item.userCompany,
            });
          });
          setCalculations(data);
        }
      }
    };

    loadUser();
  }, []);

  const userColumns = [
    {
      title: 'ID',
      dataIndex: 'no',
      key: 'no',
      render: no => <Text>{no}</Text>,
    },
    {
      title: t('filled-calculations.datetime'),
      dataIndex: 'datetime',
      key: 'datetime',
      render: date => <Text>{date}</Text>,
    },
    {
      title: t('filled-calculations.calculation-type'),
      key: 'type',
      dataIndex: 'type',
      render: type => (
        <Tag
          color={type === SURVEY_STARTUP.type ? 'green' : 'geekblue'}
          key={type}
        >
          {type === SURVEY_STARTUP.type
            ? `${t('startup.tag').toUpperCase()}`
            : `${t('estco.tag').toUpperCase()}`}
        </Tag>
      ),
    },
    {
      title: t('filled-calculations.action'),
      key: 'action',
      render: item => (
        <Space size="middle">
          <Button type="link" onClick={() => handlePdfGeneration(item)}>
            {t('filled-calculations.download-message')}
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleRemoveCalculation(item)}
          />
        </Space>
      ),
    },
  ];

  const handleRemoveCalculation = item => {
    Modal.confirm({
      title: t('filled-calculations.delete-confirm'),
      icon: <ExclamationCircleOutlined />,
      content: t('filled-calculations.delete-description'),
      okText: t('common.buttons.delete'),
      okType: 'danger',
      cancelText: t('common.buttons.cancel'),
      onOk() {
        apiReq.removeCalculation(item.id, user.token);
        setCalculations(calculations.filter(element => element !== item));
      },
    });
  };

  const adminColumns = [
    {
      title: t('filled-calculations.user'),
      dataIndex: 'user',
      key: 'user',
      render: user => <Text>{user}</Text>,
    },
    {
      title: t('filled-calculations.company'),
      dataIndex: 'company',
      key: 'company',
      render: company => <Text>{company}</Text>,
    },
  ];

  const getTableColumns = role => {
    const columns = [];
    if (role === USER) {
      for (let i = 0; i < userColumns.length; i++) {
        columns.push(userColumns[i]);
      }
    } else {
      columns.push(userColumns[0]);
      columns.push(adminColumns[0]);
      columns.push(adminColumns[1]);
      columns.push(userColumns[1]);
      columns.push(userColumns[2]);
      columns.push(userColumns[3]);
    }
    return columns;
  };

  return (
    <Layout>
      <Container>
        <Content>
          <S.TitleContainer justify="center">
            <Col md={16} sm={20} xs={24}>
              <S.Title level={2}>{t('filled-calculations.title')}</S.Title>
            </Col>
          </S.TitleContainer>
          <S.Table columns={getTableColumns(role)} dataSource={calculations} />
          {role === ADMIN && (
            <S.AdminExport>
              <Text>{t('filled-calculations.export-action')}</Text>
              <div>
                <S.AdminButtons onClick={() => handleCsvExport(EXPORT_STARTUP)}>
                  {t('filled-calculations.export-startup')}
                </S.AdminButtons>
                <S.AdminButtons
                  onClick={() => handleCsvExport(EXPORT_ESTABLISHED)}
                >
                  {t('filled-calculations.export-established')}
                </S.AdminButtons>
              </div>
            </S.AdminExport>
          )}
        </Content>
      </Container>
    </Layout>
  );
};

export default FilledSurveys;

const S = {};

S.TitleContainer = styled(Row)`
  padding: 0 60px;
  text-align: center;
  margin-bottom: 25px;
`;

S.Title = styled(Title)`
  && {
    font-weight: 900;
  }
`;

S.SubTitle = styled(Title)`
  && {
    font-weight: 700;
    margin-top: -20px;
    color: #666;
  }
`;

S.Paragraph = styled(Text)`
  && {
    line-height: 1.5;
    font-size: 16px;
    margin: 24px 0 0;
  }
`;

S.Col = styled(Col)`
  border: 1px solid transparent;
  margin: 0 25px;
  padding: 15px;

  &&:hover {
    border: 1px solid #1890ff;
    border-radius: 10px;
  }
`;

S.Blockquote = styled(Text)`
  && {
    margin: 18px 0 0;
    padding: 0 100px;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
  }
`;

S.Table = styled(Table)`
  margin: 0 20px;
`;

S.AdminExport = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px;
`;

S.AdminButtons = styled(Button)`
  margin: 0 16px;
`;
