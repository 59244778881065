import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import ProtectedAuth from './ProtectedAuth';
import ProtectedRoute from './ProtectedRoute';
import AuthContext from '../state/AuthContext';

import IndexPage from '../screens/Index';
import AboutProjectPage from '../screens/Calculation';
import CalculationVariantsPage from '../screens/Calculations';
import StartupCalculationPage from '../screens/Calculations/StartupCalculation';
import EstablishedCalculationPage from '../screens/Calculations/EstablishedCalculation';
import FilledCalculationsPage from '../screens/Calculations/FilledCalculations';
import ContactPage from '../screens/Contact';
import LoginPage from '../screens/Login';
import RegisterPage from '../screens/Register';
import ProfilePage from '../screens/Profile';
import ResetPasswordPage from '../screens/ResetPassword';
import ForgotPasswordPage from '../screens/ForgotPassword';

import StorageHandler, {
  STORAGE_USER,
  REFRESH_TOKEN,
} from '../utils/storage.handler';

const AppRouter = () => {
  const [auth, setAuth] = useState(false);

  const readStorage = async () => {
    const user = await StorageHandler.parse(STORAGE_USER);
    const refreshToken = await StorageHandler.load(REFRESH_TOKEN);
    if (refreshToken && user) {
      const decodedToken = jwtDecode(refreshToken);
      const dateNow = Date.now() / 1000;
      const isExpired = decodedToken.exp < dateNow;
      setAuth(!isExpired);
    }
  };

  useEffect(() => {
    readStorage();
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <Router>
        <Routes />
      </Router>
    </AuthContext.Provider>
  );
};

const Routes = () => {
  const Auth = useContext(AuthContext);
  const history = useHistory();
  return (
    <Switch>
      <Route exact path="/" component={IndexPage} />
      <Route exact path="/about-project" component={AboutProjectPage} />
      <Route exact path="/contact" component={ContactPage} />

      <ProtectedAuth
        exact
        auth={Auth.auth}
        path="/login"
        component={LoginPage}
      />
      <ProtectedAuth
        exact
        auth={Auth.auth}
        path="/register"
        component={RegisterPage}
      />
      <ProtectedAuth
        exact
        auth={Auth.auth}
        path="/forgot-password"
        component={ForgotPasswordPage}
      />
      <ProtectedAuth
        exact
        auth={Auth.auth}
        path="/reset-password"
        component={ResetPasswordPage}
      />
      <ProtectedRoute
        exact
        path="/calculations"
        auth={Auth}
        history={history}
        component={CalculationVariantsPage}
      />
      <ProtectedRoute
        exact
        path="/calculations/startup"
        auth={Auth}
        history={history}
        component={StartupCalculationPage}
      />
      <ProtectedRoute
        exact
        path="/calculations/established"
        auth={Auth}
        history={history}
        component={EstablishedCalculationPage}
      />
      <ProtectedRoute
        exact
        path="/calculations/filled"
        auth={Auth}
        history={history}
        component={FilledCalculationsPage}
      />
      <ProtectedRoute
        exact
        path="/profile"
        auth={Auth}
        history={history}
        component={ProfilePage}
      />
    </Switch>
  );
};

export default AppRouter;
