import client from './webClient';

// endpoints
const GET_EMAIL_CHECK = '/auth/email-check?email={#}';

const GET_ME_REQUEST = '/users/me';
const GET_ALL_USERS = '/users';

const GET_ALL_CALCULATIONS = '/calculations/filled';
const GET_ALL_CALCULATIONS_BY_USER = '/calculations/{#}/filled';
const GET_CALCULATION_BY_ID = '/calculations/filled/{#}';

const GET_CSV_EXPORT = '/calculations/reports?type={#}';

const POST_SIGN_IN = '/auth/signin';
const POST_SIGN_UP = '/auth/signup';
const POST_FORGOT_PASSWORD = '/auth/forgot-password';
const POST_RESET_PASSWORD = '/auth/reset-password?token={#}';
const POST_SEND_STARTUP = '/calculations/startup';
const POST_SEND_ESTABLISHED = '/calculations/established';
const POST_PDF_GENERATE = '/calculations/filled-pdf';
const POST_PASS_CHECK = '/auth/password-check';

const PUT_UPDATE_USER = '/users/{#}';

const DELETE_CALCULATION = '/calculations/filled/{#}';

// GET
const getMeRequest = token => {
  return client.httpGet(GET_ME_REQUEST, true, token);
};

const getAllCalculations = token => {
  return client.httpGet(GET_ALL_CALCULATIONS, true, token);
};

const getCalculationsByUser = (token, userId) => {
  return client.httpGet(
    GET_ALL_CALCULATIONS_BY_USER.replace('{#}', userId),
    true,
    token,
  );
};

const getCalculationById = (token, calculationId) => {
  return client.httpGet(
    GET_CALCULATION_BY_ID.replace('{#}', calculationId),
    true,
    token,
  );
};

// const getUserById = (token, userId) => {}

const getAllUsers = token => {
  return client.httpGet(GET_ALL_USERS, true, token);
};

const checkEmailAvailability = email => {
  return client.httpGet(GET_EMAIL_CHECK.replace('{#}', email));
};

// POST
const signInRequest = data => {
  return client.httpPost(POST_SIGN_IN, data);
};

const signUpRequest = data => {
  return client.httpPost(POST_SIGN_UP, data);
};

const sendForgotPassword = (email, lang = 'cs') => {
  return client.httpPost(POST_FORGOT_PASSWORD, email, false, null, lang);
};

const resetPassword = (token, data) => {
  return client.httpPost(POST_RESET_PASSWORD.replace('{#}', token), data);
};

const checkPassword = (token, data) => {
  return client.httpPost(POST_PASS_CHECK, data, true, token);
};

const sendStartupCalculation = (confirmationToken, data) => {
  return client.httpPost(POST_SEND_STARTUP, data, true, confirmationToken);
};

const sendEstCompanyCalculation = (token, data) => {
  return client.httpPost(POST_SEND_ESTABLISHED, data, true, token);
};

const sendPdfGenerationRequest = (token, data, lang = 'lang') => {
  return client.httpPdf(POST_PDF_GENERATE, data, token, lang);
};

const getCSVExportRequest = (type, token) => {
  return client.httpGet(GET_CSV_EXPORT.replace('{#}', type), true, token);
};

// PUT
const updateUser = (id, token, data) => {
  return client.httpPut(PUT_UPDATE_USER.replace('{#}', id), data, true, token);
};

// DELETE
const removeCalculation = (id, token) => {
  return client.httpDelete(DELETE_CALCULATION.replace('{#}', id), true, token);
};

export default {
  getMeRequest,
  getAllCalculations,
  getCalculationsByUser,
  getCalculationById,
  getAllUsers,
  checkEmailAvailability,
  signInRequest,
  signUpRequest,
  sendForgotPassword,
  resetPassword,
  checkPassword,
  sendStartupCalculation,
  sendEstCompanyCalculation,
  sendPdfGenerationRequest,
  getCSVExportRequest,
  updateUser,
  removeCalculation,
};
