export const STORAGE_USER = 'user';
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

const store = async (key, data) => {
  await localStorage.setItem(key, JSON.stringify(data));
};

const parse = async key => {
  const item = JSON.parse(JSON.parse(localStorage.getItem(key)));
  return item;
};

const save = async (key, data) => {
  await localStorage.setItem(key, data);
};

const load = async key => {
  return localStorage.getItem(key);
};

const clear = async () => {
  await localStorage.removeItem(STORAGE_USER);
};

export default {
  save,
  load,
  store,
  parse,
  clear,
};
