import React, { useContext, useState } from 'react';
import AuthContext from '../../state/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import PartnersContainer from '../../components/PartnersContainer';
import {
  Row,
  Col,
  Typography,
  Form,
  Checkbox,
  Button,
  Input,
  Card,
} from 'antd';
import Container from '../../components/Container';
import styled from 'styled-components';
import apiReq from '../../utils/api.request';
import { EMAIL_ALREADY_USED } from '../../utils/operation.status';
import handleMessage from '../../utils/message.handler';
import { useTranslation } from 'react-i18next';
import StorageHandler, {
  STORAGE_USER,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '../../utils/storage.handler';
import roleUtils, { USER, ADMIN } from '../../utils/role.utils';

const { Title, Text } = Typography;

const RegisterPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const Auth = useContext(AuthContext);
  const [form] = Form.useForm();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async form => {
    const signUpReq = await apiReq.signUpRequest(form);
    const { payload: signUpReqPayload } = signUpReq.data;
    if (signUpReq.status === 200) {
      const signInReq = await apiReq.signInRequest({
        username,
        password,
      });
      const { payload: signInReqPayload } = signInReq.data;
      if (signInReqPayload && signUpReqPayload) {
        await StorageHandler.save(
          ACCESS_TOKEN,
          signInReqPayload.data.accessToken,
        );
        await StorageHandler.save(
          REFRESH_TOKEN,
          signInReqPayload.data.refreshToken,
        );
        const { id, fullName, email, company, roles } = signUpReqPayload.data;
        let role;
        if (roles.length !== 0) {
          if (roles.includes(ADMIN)) {
            role = ADMIN;
          } else {
            role = USER;
          }
        } else {
          role = null;
        }
        StorageHandler.store(
          STORAGE_USER,
          JSON.stringify({
            id,
            fullName,
            email,
            company,
            token: signInReqPayload.data.token,
            role: roleUtils.encodeRole(role),
          }),
        );
        Auth.setAuth(true);
        history.replace('/calculations');
      }
    }
  };

  const checkEmail = async () => {
    const email = form.getFieldValue('email');
    if (email) {
      const emailCheckReq = await apiReq.checkEmailAvailability(email);
      const { meta: emailCheckReqMeta } = emailCheckReq.data;
      if (emailCheckReqMeta.opsStatus === EMAIL_ALREADY_USED) {
        form.setFields([
          {
            name: 'email',
            errors: [
              t(
                `common.validation.${handleMessage(
                  emailCheckReqMeta.opsStatus,
                )}`,
              ),
            ],
          },
        ]);
      }
    }
  };

  const checkPassword = async () => {
    const password = form.getFieldValue('password');
    if (password) {
      const isValid = password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g,
      );
      if (!isValid) {
        form.setFields([
          {
            name: 'password',
            errors: [t(`common.validation.password-validation`)],
          },
        ]);
      }
    }
  };

  return (
    <Layout>
      <Container>
        <S.Row>
          <Col sm={16} xs={24}>
            <S.Card>
              <S.Title level={2}>{t('common.menu.signup')}</S.Title>
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                form={form}
                name="register"
                onFinish={handleSubmit}
                layout="horizontal"
                size="large"
                scrollToFirstError
              >
                <Form.Item
                  name="email"
                  label={t('common.forms.email')}
                  rules={[
                    {
                      type: 'email',
                      message: t('common.validation.email-format'),
                    },
                    {
                      required: true,
                      message: t('common.validation.fill-email'),
                    },
                  ]}
                >
                  <Input
                    onBlur={checkEmail}
                    onChange={e => setUsername(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t('common.forms.password')}
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-password'),
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    onBlur={checkPassword}
                    onChange={e => setPassword(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label={t('common.forms.password-verify')}
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-password-verify'),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          t('common.validation.passwords-wrong'),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="firstName"
                  label={t('common.forms.firstname')}
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-firstname'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label={t('common.forms.lastname')}
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-lastname'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="company"
                  label={t('common.forms.company-name')}
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-company'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(t('common.validation.agreement')),
                    },
                  ]}
                >
                  <Checkbox>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('common.forms.agreement', {
                          interpolation: { escapeValue: false },
                        }),
                      }}
                    ></span>{' '}
                  </Checkbox>
                </Form.Item>
                <S.FormFooter>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {t('common.buttons.signup')}
                    </Button>
                  </Form.Item>
                  <Link to="/login">{t('common.forms.sign-to-account')}</Link>
                </S.FormFooter>
              </Form>
            </S.Card>
          </Col>
        </S.Row>
      </Container>
      <PartnersContainer />
    </Layout>
  );
};

export default RegisterPage;

const S = {};

S.TitleContainer = styled.div`
  width: 60vw;
  margin: 0 auto;
  text-align: center;
`;

S.Title = styled(Title)`
  && {
    font-weight: 900;
    text-align: center;
  }
`;

S.Row = styled(Row)`
  justify-content: center;
`;

S.Card = styled(Card)`
  border: 1px solid #ccc;
  border-radius: 5px;
`;

S.FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.ErrorMessage = styled(Text)`
  margin: -15px 0 15px;
  text-align: center;
  font-size: 12px;
  color: red;
`;

S.ErrorCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
