import {
  PASSWORD_CHANGED,
  INVALID_CREDENTIALS,
  USER_NOT_FOUND,
  EMAIL_ALREADY_USED,
  SURVEY_NOT_FOUND,
  INVALID_REQUEST,
  EMAIL_AVAILABLE,
  BAD_REQUEST,
  RESET_LINK_NOT_SENT,
  VERIFICATION_FAILED,
} from './operation.status';

export default function handleMessage(operationStatusCode) {
  let key = '';
  switch (operationStatusCode) {
    case INVALID_CREDENTIALS:
      key = 'invalid-credentials';
      break;
    case VERIFICATION_FAILED:
      key = 'verification-failed';
      break;
    case USER_NOT_FOUND:
      key = 'user-not-found';
      break;
    case EMAIL_ALREADY_USED:
      key = 'email-already-used';
      break;
    case SURVEY_NOT_FOUND:
      key = 'survey-not-found';
      break;
    case RESET_LINK_NOT_SENT:
      key = 'reset-link-not-sent';
      break;
    case INVALID_REQUEST:
      key = 'invalid-request';
      break;
    case BAD_REQUEST:
      key = 'bad-request';
      break;
    case EMAIL_AVAILABLE:
      key = 'email-available';
      break;
    case PASSWORD_CHANGED:
      key = 'password-change-success';
      break;
    default:
      key = 'unknown-error';
      break;
  }
  return key;
}
