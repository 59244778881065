import React from 'react';
import { Layout } from 'antd';
import Footer from '../Footer';
import './style.less';
import NavBar from '../NavBar';
// import NavBar from '../NavBarOld';
import Background from '../Background';

const Wrapper = props => {
  return (
    <Layout className="Layout">
      <div className="Header">
        <NavBar />
      </div>
      <Background>
        <Layout.Content className="Content">{props.children}</Layout.Content>
      </Background>
      <Layout.Footer className="Footer">
        <Footer />
      </Layout.Footer>
    </Layout>
  );
};

export default Wrapper;
