import React, { useEffect, useState } from 'react';
import StorageHandler, { STORAGE_USER } from '../../utils/storage.handler';
import Layout from '../../components/Layout';
import PartnersContainer from '../../components/PartnersContainer';
import {
  Row,
  Col,
  Typography,
  Form,
  Card,
  Button,
  Input,
  Divider,
  notification,
} from 'antd';
import Container from '../../components/Container';
import styled from 'styled-components';
import apiReq from '../../utils/api.request';
import handleMessage from '../../utils/message.handler';
import { useTranslation } from 'react-i18next';
import {
  EMAIL_ALREADY_USED,
  VERIFICATION_FAILED,
} from '../../utils/operation.status';
import {
  NOTIFICATION_USER_DETAILS,
  NOTIFICATION_USER_PASSWORD,
} from '../../utils/constants';

const { Title, Text } = Typography;

const ProfilePage = () => {
  const { t } = useTranslation();
  const [userForm] = Form.useForm();
  const [passForm] = Form.useForm();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loadUser = async () => {
      const u = await StorageHandler.parse(STORAGE_USER);
      if (u) {
        const { id, fullName, email, company, token, role } = u;
        const name = fullName.split(' ');
        setUser({
          id,
          firstName: name[0],
          lastName: name[1],
          email,
          company,
          token,
          role,
        });
        userForm.setFields([
          {
            name: 'firstName',
            value: name[0],
          },
          {
            name: 'lastName',
            value: name[1],
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'company',
            value: company,
          },
        ]);
      }
    };
    loadUser();
  }, []);

  const openNotification = type => {
    notification.open({
      message:
        type === NOTIFICATION_USER_DETAILS
          ? t('common.forms.profile-change')
          : t('common.forms.password-change'),
      description:
        type === NOTIFICATION_USER_DETAILS
          ? t('profile.data-change-success')
          : t('profile.password-change'),
    });
  };

  const handleUpdateProfile = async form => {
    const data = {};
    if (form.firstName !== user.firstName) data['firstName'] = form.firstName;
    if (form.lastName !== user.lastName) data['lastName'] = form.lastName;
    if (form.email !== user.email) data['email'] = form.email;
    if (form.company !== user.company) data['company'] = form.company;

    const userDetailsReq = await apiReq.updateUser(user.id, user.token, data);
    const { payload, meta } = userDetailsReq.data;
    if (meta.statusCode === 200) {
      const { fullName, email, company } = payload.data;
      const name = fullName.split(' ');
      setUser({
        ...user,
        firstName: name[0],
        lastName: name[1],
        email,
        company,
      });
      const storageUser = {
        id: user.id,
        fullName,
        email,
        company,
        token: user.token,
        role: user.role,
      };
      StorageHandler.store(STORAGE_USER, JSON.stringify(storageUser));
      openNotification(NOTIFICATION_USER_DETAILS);
    }
  };

  const handlePasswordChange = async form => {
    const userPassReq = await apiReq.updateUser(user.id, user.token, {
      password: form.password,
    });
    const { meta } = userPassReq.data;
    if (meta.statusCode === 200) {
      openNotification(NOTIFICATION_USER_PASSWORD);
      passForm.setFields([
        {
          name: 'currentPassword',
          value: '',
        },
        {
          name: 'password',
          value: '',
        },
        {
          name: 'confirm',
          value: '',
        },
      ]);
    }
  };

  const checkEmail = async () => {
    if (user.email !== userForm.getFieldValue('email')) {
      const email = userForm.getFieldValue('email');
      if (email) {
        const emailCheckReq = await apiReq.checkEmailAvailability(email);
        const { meta: emailCheckReqMeta } = emailCheckReq.data;
        if (emailCheckReqMeta.opsStatus === EMAIL_ALREADY_USED) {
          userForm.setFields([
            {
              name: 'email',
              errors: [
                t(
                  `common.validation.${handleMessage(
                    emailCheckReqMeta.opsStatus,
                  )}`,
                ),
              ],
            },
          ]);
        }
      }
    }
  };

  const checkOldPassword = async () => {
    const currentPassword = passForm.getFieldValue('currentPassword');
    if (currentPassword) {
      const passCheckReq = await apiReq.checkPassword(user.token, {
        password: currentPassword,
      });
      const { meta } = passCheckReq.data;
      if (meta.opsStatus === VERIFICATION_FAILED) {
        passForm.setFields([
          {
            name: 'currentPassword',
            errors: [t(`password.${handleMessage(meta.opsStatus)}`)],
          },
        ]);
      }
    }
  };

  const checkPasswordComplexity = async () => {
    const password = passForm.getFieldValue('password');
    if (password) {
      const isValid = password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g,
      );
      if (!isValid) {
        passForm.setFields([
          {
            name: 'password',
            errors: [t(`common.validation.password-validation`)],
          },
        ]);
      }
    }
  };

  return (
    <Layout>
      <Container>
        <S.Row>
          <Col md={16} sm={20} xs={24}>
            <S.Title level={2}>{t('common.menu.profile')}</S.Title>
            <S.Card>
              <S.Title level={3}>{t('common.forms.profile-change')}</S.Title>
              <S.Form
                name="profile"
                onFinish={handleUpdateProfile}
                form={userForm}
                layout="vertical"
                wrapperCol={{ span: 20, offset: 2 }}
                size="large"
              >
                <Row>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="firstName"
                      label={t('common.forms.firstname')}
                      rules={[
                        {
                          required: true,
                          message: t('common.validation.fill-firstname'),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="lastName"
                      label={t('common.forms.lastname')}
                      rules={[
                        {
                          required: true,
                          message: t('common.validation.fill-lastname'),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="email"
                      label={t('common.forms.email')}
                      rules={[
                        {
                          type: 'email',
                          message: t('common.validation.email-format'),
                        },
                        {
                          required: true,
                          message: t('common.validation.fill-email'),
                        },
                      ]}
                    >
                      <Input onBlur={checkEmail} />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name="company"
                      label={t('common.forms.company-name')}
                      rules={[
                        {
                          required: true,
                          message: t('common.validation.fill-company'),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <S.FormFooter>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="save-profile-button"
                  >
                    {t('common.buttons.save-changes')}
                  </Button>
                </S.FormFooter>
              </S.Form>
            </S.Card>
            <Divider />
            <S.Card>
              <S.Title level={3}>{t('common.forms.password-change')}</S.Title>
              <S.Form
                name="password"
                onFinish={handlePasswordChange}
                form={passForm}
                layout="vertical"
                wrapperCol={{ span: 20, offset: 2 }}
                size="large"
              >
                <Form.Item
                  name="currentPassword"
                  label={t('common.forms.current-password')}
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-current-password'),
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password onBlur={checkOldPassword} />
                </Form.Item>

                <Form.Item
                  name="password"
                  label={t('common.forms.password')}
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-password'),
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password onBlur={checkPasswordComplexity} />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label={t('common.forms.password-verify')}
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-password-verify'),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          t('common.validation.passwords-wrong'),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <S.FormFooter>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="password-form-button"
                  >
                    {t('common.buttons.password-change')}
                  </Button>
                </S.FormFooter>
              </S.Form>
            </S.Card>
          </Col>
        </S.Row>
      </Container>
      <PartnersContainer />
    </Layout>
  );
};

export default ProfilePage;

const S = {};

S.TitleContainer = styled.div`
  width: 60vw;
  margin: 0 auto;
  text-align: center;
`;

S.Title = styled(Title)`
  && {
    margin: 0 0 25px;
    font-weight: 900;
    text-align: center;
  }
`;

S.SubTitle = styled(Title)`
  && {
    font-weight: 700;
    margin-top: -20px;
    color: #666;
  }
`;

S.Row = styled(Row)`
  justify-content: center;
`;

S.AvatarImg = styled.img`
  height: 75px;
  width: 75px;
`;

S.Card = styled(Card)`
  border: 1px solid #ccc;
  border-radius: 5px;
`;

S.Form = styled(Form)`
  display: flex;
  flex-direction: column;
`;

S.FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.AdditNavRow = styled(Row)`
  margin-top: 25px;
`;

S.ErrorMessage = styled(Text)`
  text-align: center;
  font-size: 14px;
  color: red;
  margin-bottom: 15px;
`;
