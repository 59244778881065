import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Content from '../../../components/Content';
import Container from '../../../components/Container';
import styled from 'styled-components';
import {
  Row,
  Col,
  Typography,
  Steps,
  Form,
  Button,
  Radio,
  InputNumber,
  Checkbox,
  Tooltip,
  Divider,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import establishedForm from '../../../assets/forms/established.form';
import apiReq from '../../../utils/api.request';
import StorageHandler, { STORAGE_USER } from '../../../utils/storage.handler';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;
const { Step } = Steps;

const EstablishedSurvey = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(0);
  const [currency, setCurrency] = useState('czk');
  const [form, setForm] = useState({
    qid_1: { id: 0, value: 0 },
    qid_2: { id: 0, value: 0 },
    qid_3: {
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
      sub_5: 0,
      sub_6: 0,
    },
    qid_4: 0,
    qid_5: { id: 0, value: 0 },
    qid_6: { id: 0, value: 0 },
    qid_7: { id: 0, value: 0 },
    qid_8: { id: 0, value: 0 },
    qid_9: { id: 0, value: 0 },
    qid_10: [],
    qid_11: 0,
    qid_12: { id: 0, value: 0 },
    qid_13: {
      total: 0,
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
      sub_5: 0,
      sub_6: 0,
    },
    qid_14: {
      total: 0,
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
      sub_5: 0,
    },
    qid_15: {
      total: 0,
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
    },
    qid_16: {
      total: 0,
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
    },
    qid_17: {
      total: 0,
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
      sub_5: 0,
      sub_6: 0,
      sub_7: 0,
    },
    qid_18: {
      total: 0,
      sub_1: 0,
      sub_2: 0,
    },
    qid_19: {
      total: 0,
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
    },
    qid_20: {
      sub_0: 0,
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
      sub_5: 0,
    },
    qid_21: {
      total: 0,
    },
    qid_22: {
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
      sub_5: 0,
    },
    qid_23: {
      sub_0: 0,
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
      sub_5: 0,
    },
    qid_24: {
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
      sub_5: 0,
    },
    qid_25: {
      sub_1: 0,
      sub_2: 0,
      sub_3: 0,
      sub_4: 0,
      sub_5: 0,
    },
  });

  const [user, setUser] = useState(null);

  const currencyOptions = [
    {
      label: t('common.forms.currency-czk'),
      value: 'czk',
    },
    {
      label: t('common.forms.currency-eur'),
      value: 'eur',
    },
  ];

  useEffect(() => {
    const loadUser = async () => {
      setUser(await StorageHandler.parse(STORAGE_USER));
    };
    loadUser();
  }, []);

  const onFinish = async () => {
    const qid10array = [];
    form.qid_10.forEach(item => qid10array.push(JSON.parse(item)));
    const data = {
      survey: {
        qid1: form.qid_1,
        qid2: form.qid_2,
        qid3: [
          form.qid_3.sub_1,
          form.qid_3.sub_2,
          form.qid_3.sub_3,
          form.qid_3.sub_4,
          form.qid_3.sub_5,
          form.qid_3.sub_6,
        ],
        qid4: form.qid_4,
        qid5: form.qid_5,
        qid6: form.qid_6,
        qid7: form.qid_7,
        qid8: form.qid_8,
        qid9: form.qid_9,
        qid10: qid10array,
        qid11: form.qid_11,
        qid12: form.qid_12,
        qid13: form.qid_13.total,
        qid14: form.qid_14.total,
        qid15: form.qid_15.total,
        qid16: form.qid_16.total,
        qid17: form.qid_17.total,
        qid18: form.qid_18.total,
        qid19: form.qid_19.total,
        qid20: [
          form.qid_20.sub_0,
          form.qid_20.sub_1,
          form.qid_20.sub_2,
          form.qid_20.sub_3,
          form.qid_20.sub_4,
          form.qid_20.sub_5,
        ],
        qid21: form.qid_21.total,
        qid22: [
          form.qid_22.sub_1,
          form.qid_22.sub_2,
          form.qid_22.sub_3,
          form.qid_22.sub_4,
          form.qid_22.sub_5,
        ],
        qid23: [
          form.qid_23.sub_0,
          form.qid_23.sub_1,
          form.qid_23.sub_2,
          form.qid_23.sub_3,
          form.qid_23.sub_4,
          form.qid_23.sub_5,
        ],
        qid24: [
          form.qid_24.sub_1,
          form.qid_24.sub_2,
          form.qid_24.sub_3,
          form.qid_24.sub_4,
          form.qid_24.sub_5,
        ],
        qid25: [
          form.qid_25.sub_1,
          form.qid_25.sub_2,
          form.qid_25.sub_3,
          form.qid_25.sub_4,
          form.qid_25.sub_5,
        ],
      },
    };
    await apiReq.sendEstCompanyCalculation(user.token, data);
    history.push('/calculations/filled');
  };

  const CurrencyLabel = ({ currency }) => (
    <S.CurrencyLabel>
      {currency === 'czk'
        ? t('common.forms.currency-czk')
        : t('common.forms.currency-eur')}
    </S.CurrencyLabel>
  );

  const questionsA = establishedForm[0].questions;
  const questionsB = establishedForm[1].questions;
  const questionsC = establishedForm[2].questions;
  const questionsD = establishedForm[3].questions;

  const sumAll = values => {
    let sum = 0;
    values.forEach(value => {
      if (value) {
        sum += value;
      } else {
        sum += 0;
      }
    });
    return sum;
  };

  const getDescription = step => {
    switch (step) {
      case 0:
        return t('estco.step-description-1');
      case 1:
        return t('estco.step-description-2');
      case 2:
        return t('estco.step-description-3');
      case 3:
        return t('estco.step-description-4');
      default:
        return '';
    }
  };

  const DefaultRadioButton = () => (
    <Radio.Button
      key={`q_a_0`}
      value={JSON.stringify({
        id: 0,
        value: 0,
      })}
    >
      {t('common.forms.radio-unanswered')}
    </Radio.Button>
  );

  return (
    <Layout>
      <Container>
        <Content>
          <S.TitleContainer justify="center">
            <Col md={16} sm={20} xs={24}>
              <S.Title level={2}>{t('estco.title')}</S.Title>
            </Col>
          </S.TitleContainer>
          <S.StepperBar>
            <Steps progressDot current={step}>
              <Step description={t('estco.step-title-1')} />
              <Step description={t('estco.step-title-2')} />
              <Step description={t('estco.step-title-3')} />
              <Step description={t('estco.step-title-4')} />
            </Steps>
            {step < 4 && (
              <S.PhaseDescription>{getDescription(step)}</S.PhaseDescription>
            )}
          </S.StepperBar>

          <S.InfoBar justify="center">
            <Col md={12} sm={24}>
              <S.InfoBarMessages>
                <S.InfoBarText>
                  <InfoCircleOutlined color="#888" />{' '}
                  {t('common.forms.message-floating-point')}
                </S.InfoBarText>
                <S.InfoBarText>
                  <InfoCircleOutlined color="#888" />{' '}
                  {t('common.forms.message-no-answer')}
                </S.InfoBarText>
              </S.InfoBarMessages>
            </Col>
            <Col md={12} sm={24}>
              <S.CurrencyChange>
                <S.CurrencyChangeLabel>
                  {t('common.currency-change')}
                </S.CurrencyChangeLabel>
                <Radio.Group
                  options={currencyOptions}
                  onChange={e => setCurrency(e.target.value)}
                  value={currency}
                  optionType="button"
                  buttonStyle="solid"
                />
              </S.CurrencyChange>
            </Col>
          </S.InfoBar>

          <S.Form onFinish={onFinish} size="default">
            {step === 0 && (
              <div>
                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsA[0].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsA[0].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsA[0].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsA[0].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsA[0].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={e =>
                                setForm({
                                  ...form,
                                  qid_1: JSON.parse(e.target.value),
                                })
                              }
                            >
                              {questionsA[0].answers.map(answer => (
                                <Radio.Button
                                  key={`q_${questionsA[0].qid}_a_${answer.id}`}
                                  defaultValue={JSON.stringify({
                                    id: 0,
                                    value: 0,
                                  })}
                                  value={JSON.stringify({
                                    id: answer.id,
                                    value: answer.value,
                                  })}
                                >
                                  {answer.text[i18n.language === 'cs' ? 0 : 1]}
                                </Radio.Button>
                              ))}
                              <DefaultRadioButton />
                            </Radio.Group>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsA[1].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsA[1].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsA[1].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsA[1].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsA[1].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={e =>
                                setForm({
                                  ...form,
                                  qid_2: JSON.parse(e.target.value),
                                })
                              }
                            >
                              {questionsA[1].answers.map(answer => (
                                <Radio.Button
                                  key={`q_${questionsA[1].qid}_a_${answer.id}`}
                                  defaultValue={JSON.stringify({
                                    id: 0,
                                    value: 0,
                                  })}
                                  value={JSON.stringify({
                                    id: answer.id,
                                    value: answer.value,
                                  })}
                                >
                                  {answer.text[i18n.language === 'cs' ? 0 : 1]}
                                </Radio.Button>
                              ))}
                              <DefaultRadioButton />
                            </Radio.Group>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsA[2].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsA[2].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsA[2].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsA[2].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsA[2].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  questionsA[2].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputNumber
                                min={0}
                                placeholder={0}
                                onChange={value =>
                                  setForm({
                                    ...form,
                                    qid_3: {
                                      ...form.qid_3,
                                      sub_1: value,
                                    },
                                  })
                                }
                              />
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsA[2].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputNumber
                                min={0}
                                placeholder={0}
                                onChange={value =>
                                  setForm({
                                    ...form,
                                    qid_3: {
                                      ...form.qid_3,
                                      sub_2: value,
                                    },
                                  })
                                }
                              />
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsA[2].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputNumber
                                min={0}
                                placeholder={0}
                                onChange={value =>
                                  setForm({
                                    ...form,
                                    qid_3: {
                                      ...form.qid_3,
                                      sub_3: value,
                                    },
                                  })
                                }
                              />
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsA[2].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputNumber
                                min={0}
                                placeholder={0}
                                onChange={value =>
                                  setForm({
                                    ...form,
                                    qid_3: {
                                      ...form.qid_3,
                                      sub_4: value,
                                    },
                                  })
                                }
                              />
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsA[2].subs[4].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputNumber
                                min={0}
                                placeholder={0}
                                onChange={value =>
                                  setForm({
                                    ...form,
                                    qid_3: {
                                      ...form.qid_3,
                                      sub_5: value,
                                    },
                                  })
                                }
                              />
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsA[2].subs[5].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputNumber
                                min={0}
                                placeholder={0}
                                onChange={value =>
                                  setForm({
                                    ...form,
                                    qid_3: {
                                      ...form.qid_3,
                                      sub_6: value,
                                    },
                                  })
                                }
                              />
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsA[3].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsA[3].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsA[3].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsA[3].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsA[3].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <InputNumber
                              min={0}
                              value={form.qid_4}
                              onChange={value => {
                                let parsedValue = value;
                                if (!value || value < 0) parsedValue = 0;
                                setForm({ ...form, qid_4: parsedValue });
                              }}
                            />
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            {/* block B */}
            {step === 1 && (
              <div>
                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsB[0].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsB[0].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsB[0].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsB[0].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsB[0].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={e =>
                                setForm({
                                  ...form,
                                  qid_5: JSON.parse(e.target.value),
                                })
                              }
                            >
                              {questionsB[0].answers.map(answer => (
                                <Radio.Button
                                  key={`q_${questionsB[0].qid}_a_${answer.id}`}
                                  defaultValue={JSON.stringify({
                                    id: 0,
                                    value: 0,
                                  })}
                                  value={JSON.stringify({
                                    id: answer.id,
                                    value: answer.value,
                                  })}
                                >
                                  {answer.text[i18n.language === 'cs' ? 0 : 1]}
                                </Radio.Button>
                              ))}
                              <DefaultRadioButton />
                            </Radio.Group>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsB[1].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsB[1].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsB[1].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsB[1].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsB[1].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={e =>
                                setForm({
                                  ...form,
                                  qid_6: JSON.parse(e.target.value),
                                })
                              }
                            >
                              {questionsB[1].answers.map(answer => (
                                <Radio.Button
                                  key={`q_${questionsB[1].qid}_a_${answer.id}`}
                                  defaultValue={JSON.stringify({
                                    id: 0,
                                    value: 0,
                                  })}
                                  value={JSON.stringify({
                                    id: answer.id,
                                    value: answer.value,
                                  })}
                                >
                                  {answer.text[i18n.language === 'cs' ? 0 : 1]}
                                </Radio.Button>
                              ))}
                              <DefaultRadioButton />
                            </Radio.Group>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsB[2].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsB[2].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsB[2].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsB[2].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsB[2].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={e =>
                                setForm({
                                  ...form,
                                  qid_7: JSON.parse(e.target.value),
                                })
                              }
                            >
                              {questionsB[2].answers.map(answer => (
                                <Radio.Button
                                  key={`q_${questionsB[2].qid}_a_${answer.id}`}
                                  defaultValue={JSON.stringify({
                                    id: 0,
                                    value: 0,
                                  })}
                                  value={JSON.stringify({
                                    id: answer.id,
                                    value: answer.value,
                                  })}
                                >
                                  {answer.text[i18n.language === 'cs' ? 0 : 1]}
                                </Radio.Button>
                              ))}
                              <DefaultRadioButton />
                            </Radio.Group>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsB[3].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsB[3].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsB[3].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsB[3].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsB[3].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={e =>
                                setForm({
                                  ...form,
                                  qid_8: JSON.parse(e.target.value),
                                })
                              }
                            >
                              {questionsB[3].answers.map(answer => (
                                <Radio.Button
                                  key={`q_${questionsB[3].qid}_a_${answer.id}`}
                                  defaultValue={JSON.stringify({
                                    id: 0,
                                    value: 0,
                                  })}
                                  value={JSON.stringify({
                                    id: answer.id,
                                    value: answer.value,
                                  })}
                                >
                                  {answer.text[i18n.language === 'cs' ? 0 : 1]}
                                </Radio.Button>
                              ))}
                              <DefaultRadioButton />
                            </Radio.Group>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsB[4].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsB[4].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsB[4].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsB[4].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsB[4].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={e =>
                                setForm({
                                  ...form,
                                  qid_9: JSON.parse(e.target.value),
                                })
                              }
                            >
                              {questionsB[4].answers.map(answer => (
                                <Radio.Button
                                  key={`q_${questionsB[4].qid}_a_${answer.id}`}
                                  defaultValue={JSON.stringify({
                                    id: 0,
                                    value: 0,
                                  })}
                                  value={JSON.stringify({
                                    id: answer.id,
                                    value: answer.value,
                                  })}
                                >
                                  {answer.text[i18n.language === 'cs' ? 0 : 1]}
                                </Radio.Button>
                              ))}
                              <DefaultRadioButton />
                            </Radio.Group>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsB[5].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsB[5].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsB[5].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsB[5].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsB[5].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <Checkbox.Group
                              onChange={value =>
                                setForm({ ...form, qid_10: value })
                              }
                            >
                              {questionsB[5].answers.map(answer => (
                                <Checkbox
                                  key={`q_${questionsB[5].qid}_a_${answer.id}`}
                                  defaultValue={JSON.stringify({
                                    id: 0,
                                    value: 0,
                                  })}
                                  value={JSON.stringify({
                                    id: answer.id,
                                    value: answer.value,
                                  })}
                                >
                                  {answer.text[i18n.language === 'cs' ? 0 : 1]}
                                </Checkbox>
                              ))}
                            </Checkbox.Group>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsB[6].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsB[6].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsB[6].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsB[6].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsB[6].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <InputNumber
                              min={0}
                              max={100}
                              value={form.qid_11}
                              onChange={value => {
                                let parsedValue = value;
                                if (value > 100) parsedValue = 100;
                                if (!value || value < 0) parsedValue = 0;
                                setForm({ ...form, qid_11: parsedValue });
                              }}
                            />
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsB[7].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsB[7].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsB[7].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsB[7].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsB[7].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={e =>
                                setForm({
                                  ...form,
                                  qid_12: JSON.parse(e.target.value),
                                })
                              }
                            >
                              {questionsB[7].answers.map(answer => (
                                <Radio.Button
                                  key={`q_${questionsB[7].qid}_a_${answer.id}`}
                                  defaultValue={JSON.stringify({
                                    id: 0,
                                    value: 0,
                                  })}
                                  value={JSON.stringify({
                                    id: answer.id,
                                    value: answer.value,
                                  })}
                                >
                                  {answer.text[i18n.language === 'cs' ? 0 : 1]}
                                </Radio.Button>
                              ))}
                              <DefaultRadioButton />
                            </Radio.Group>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            {/* block C */}
            {step === 2 && (
              <div>
                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsC[0].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsC[0].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsC[0].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              <S.Tooltip
                                title={
                                  establishedForm[2].phase_tooltip[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              >
                                <InfoCircleOutlined />
                              </S.Tooltip>
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  establishedForm[2].phase_total[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  value={
                                    form.qid_13.total !== 0
                                      ? form.qid_13.total
                                      : ''
                                  }
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_13: { ...form.qid_13, total: value },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                            <S.Divider>
                              <Col md={4}>
                                <S.DividerLine />
                              </Col>
                              <Col md={14} offset={1}>
                                <S.PhaseHintText type="secondary">
                                  {
                                    establishedForm[2].phase_hint[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                </S.PhaseHintText>
                              </Col>
                              <Col md={4} offset={1}>
                                <S.DividerLine />
                              </Col>
                            </S.Divider>
                            <S.Field>
                              <Text>
                                {
                                  questionsC[0].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_13: {
                                        ...form.qid_13,
                                        sub_1: value,
                                        total: sumAll([
                                          value,
                                          form.qid_13.sub_2,
                                          form.qid_13.sub_3,
                                          form.qid_13.sub_4,
                                          form.qid_13.sub_5,
                                          form.qid_13.sub_6,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[0].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_13: {
                                        ...form.qid_13,
                                        sub_2: value,
                                        total: sumAll([
                                          form.qid_13.sub_1,
                                          value,
                                          form.qid_13.sub_3,
                                          form.qid_13.sub_4,
                                          form.qid_13.sub_5,
                                          form.qid_13.sub_6,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[0].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_13: {
                                        ...form.qid_13,
                                        sub_3: value,
                                        total: sumAll([
                                          form.qid_13.sub_1,
                                          form.qid_13.sub_2,
                                          value,
                                          form.qid_13.sub_4,
                                          form.qid_13.sub_5,
                                          form.qid_13.sub_6,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[0].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_13: {
                                        ...form.qid_13,
                                        sub_4: value,
                                        total: sumAll([
                                          form.qid_13.sub_1,
                                          form.qid_13.sub_2,
                                          form.qid_13.sub_3,
                                          value,
                                          form.qid_13.sub_5,
                                          form.qid_13.sub_6,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[0].subs[4].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_13: {
                                        ...form.qid_13,
                                        sub_5: value,
                                        total: sumAll([
                                          form.qid_13.sub_1,
                                          form.qid_13.sub_2,
                                          form.qid_13.sub_3,
                                          form.qid_13.sub_4,
                                          value,
                                          form.qid_13.sub_6,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[0].subs[5].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_13: {
                                        ...form.qid_13,
                                        sub_6: value,
                                        total: sumAll([
                                          form.qid_13.sub_1,
                                          form.qid_13.sub_2,
                                          form.qid_13.sub_3,
                                          form.qid_13.sub_4,
                                          form.qid_13.sub_5,
                                          value,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsC[1].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsC[1].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsC[1].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              <S.Tooltip
                                title={
                                  establishedForm[2].phase_tooltip[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              >
                                <InfoCircleOutlined />
                              </S.Tooltip>
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  establishedForm[2].phase_total[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  value={
                                    form.qid_14.total !== 0
                                      ? form.qid_14.total
                                      : ''
                                  }
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_14: { ...form.qid_14, total: value },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                            <S.Divider>
                              <Col md={4}>
                                <S.DividerLine />
                              </Col>
                              <Col md={14} offset={1}>
                                <S.PhaseHintText type="secondary">
                                  {
                                    establishedForm[2].phase_hint[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                </S.PhaseHintText>
                              </Col>
                              <Col md={4} offset={1}>
                                <S.DividerLine />
                              </Col>
                            </S.Divider>
                            <S.Field>
                              <Text>
                                {
                                  questionsC[1].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_14: {
                                        ...form.qid_14,
                                        sub_1: value,
                                        total: sumAll([
                                          value,
                                          form.qid_14.sub_2,
                                          form.qid_14.sub_3,
                                          form.qid_14.sub_4,
                                          form.qid_14.sub_5,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[1].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_14: {
                                        ...form.qid_14,
                                        sub_2: value,
                                        total: sumAll([
                                          form.qid_14.sub_1,
                                          value,
                                          form.qid_14.sub_3,
                                          form.qid_14.sub_4,
                                          form.qid_14.sub_5,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[1].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_14: {
                                        ...form.qid_14,
                                        sub_3: value,
                                        total: sumAll([
                                          form.qid_14.sub_1,
                                          form.qid_14.sub_2,
                                          value,
                                          form.qid_14.sub_4,
                                          form.qid_14.sub_5,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[1].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_14: {
                                        ...form.qid_14,
                                        sub_4: value,
                                        total: sumAll([
                                          form.qid_14.sub_1,
                                          form.qid_14.sub_2,
                                          form.qid_14.sub_3,
                                          value,
                                          form.qid_14.sub_5,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[1].subs[4].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_14: {
                                        ...form.qid_14,
                                        sub_5: value,
                                        total: sumAll([
                                          form.qid_14.sub_1,
                                          form.qid_14.sub_2,
                                          form.qid_14.sub_3,
                                          form.qid_14.sub_4,
                                          value,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsC[2].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsC[2].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsC[2].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              <S.Tooltip
                                title={
                                  establishedForm[2].phase_tooltip[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              >
                                <InfoCircleOutlined />
                              </S.Tooltip>
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  establishedForm[2].phase_total[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  value={
                                    form.qid_15.total !== 0
                                      ? form.qid_15.total
                                      : ''
                                  }
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_15: { ...form.qid_15, total: value },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                            <S.Divider>
                              <Col md={4}>
                                <S.DividerLine />
                              </Col>
                              <Col md={14} offset={1}>
                                <S.PhaseHintText type="secondary">
                                  {
                                    establishedForm[2].phase_hint[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                </S.PhaseHintText>
                              </Col>
                              <Col md={4} offset={1}>
                                <S.DividerLine />
                              </Col>
                            </S.Divider>
                            <S.Field>
                              <Text>
                                {
                                  questionsC[2].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_15: {
                                        ...form.qid_15,
                                        sub_1: value,
                                        total: sumAll([
                                          value,
                                          form.qid_15.sub_2,
                                          form.qid_15.sub_3,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[2].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_15: {
                                        ...form.qid_15,
                                        sub_2: value,
                                        total: sumAll([
                                          form.qid_15.sub_1,
                                          value,
                                          form.qid_15.sub_3,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[2].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_15: {
                                        ...form.qid_15,
                                        sub_3: value,
                                        total: sumAll([
                                          form.qid_15.sub_1,
                                          form.qid_15.sub_2,
                                          value,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsC[3].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsC[3].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsC[3].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              <S.Tooltip
                                title={
                                  establishedForm[2].phase_tooltip[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              >
                                <InfoCircleOutlined />
                              </S.Tooltip>
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  establishedForm[2].phase_total[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  value={
                                    form.qid_16.total !== 0
                                      ? form.qid_16.total
                                      : ''
                                  }
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_16: { ...form.qid_16, total: value },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                            <S.Divider>
                              <Col md={4}>
                                <S.DividerLine />
                              </Col>
                              <Col md={14} offset={1}>
                                <S.PhaseHintText type="secondary">
                                  {
                                    establishedForm[2].phase_hint[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                </S.PhaseHintText>
                              </Col>
                              <Col md={4} offset={1}>
                                <S.DividerLine />
                              </Col>
                            </S.Divider>
                            <S.Field>
                              <Text>
                                {
                                  questionsC[3].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_16: {
                                        ...form.qid_16,
                                        sub_1: value,
                                        total: sumAll([
                                          value,
                                          form.qid_16.sub_2,
                                          form.qid_16.sub_3,
                                          form.qid_16.sub_4,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[3].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_16: {
                                        ...form.qid_16,
                                        sub_2: value,
                                        total: sumAll([
                                          form.qid_16.sub_1,
                                          value,
                                          form.qid_16.sub_3,
                                          form.qid_16.sub_4,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[3].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_16: {
                                        ...form.qid_16,
                                        sub_3: value,
                                        total: sumAll([
                                          form.qid_16.sub_1,
                                          form.qid_16.sub_2,
                                          value,
                                          form.qid_16.sub_4,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[3].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_16: {
                                        ...form.qid_16,
                                        sub_4: value,
                                        total: sumAll([
                                          form.qid_16.sub_1,
                                          form.qid_16.sub_2,
                                          form.qid_16.sub_3,
                                          value,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsC[4].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsC[4].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsC[4].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              <S.Tooltip
                                title={
                                  establishedForm[2].phase_tooltip[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              >
                                <InfoCircleOutlined />
                              </S.Tooltip>
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  establishedForm[2].phase_total[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  value={
                                    form.qid_17.total !== 0
                                      ? form.qid_17.total
                                      : ''
                                  }
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_17: { ...form.qid_17, total: value },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                            <S.Divider>
                              <Col md={4}>
                                <S.DividerLine />
                              </Col>
                              <Col md={14} offset={1}>
                                <S.PhaseHintText type="secondary">
                                  {
                                    establishedForm[2].phase_hint[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                </S.PhaseHintText>
                              </Col>
                              <Col md={4} offset={1}>
                                <S.DividerLine />
                              </Col>
                            </S.Divider>
                            <S.Field>
                              <Text>
                                {
                                  questionsC[4].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_17: {
                                        ...form.qid_17,
                                        sub_1: value,
                                        total: sumAll([
                                          value,
                                          form.qid_17.sub_2,
                                          form.qid_17.sub_3,
                                          form.qid_17.sub_4,
                                          form.qid_17.sub_5,
                                          form.qid_17.sub_6,
                                          form.qid_17.sub_7,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[4].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_17: {
                                        ...form.qid_17,
                                        sub_2: value,
                                        total: sumAll([
                                          form.qid_17.sub_1,
                                          value,
                                          form.qid_17.sub_3,
                                          form.qid_17.sub_4,
                                          form.qid_17.sub_5,
                                          form.qid_17.sub_6,
                                          form.qid_17.sub_7,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[4].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_17: {
                                        ...form.qid_17,
                                        sub_3: value,
                                        total: sumAll([
                                          form.qid_17.sub_1,
                                          form.qid_17.sub_2,
                                          value,
                                          form.qid_17.sub_4,
                                          form.qid_17.sub_5,
                                          form.qid_17.sub_6,
                                          form.qid_17.sub_7,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[4].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_17: {
                                        ...form.qid_17,
                                        sub_4: value,
                                        total: sumAll([
                                          form.qid_17.sub_1,
                                          form.qid_17.sub_2,
                                          form.qid_17.sub_3,
                                          value,
                                          form.qid_17.sub_5,
                                          form.qid_17.sub_6,
                                          form.qid_17.sub_7,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[4].subs[4].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_17: {
                                        ...form.qid_17,
                                        sub_5: value,
                                        total: sumAll([
                                          form.qid_17.sub_1,
                                          form.qid_17.sub_2,
                                          form.qid_17.sub_3,
                                          form.qid_17.sub_4,
                                          value,
                                          form.qid_17.sub_6,
                                          form.qid_17.sub_7,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[4].subs[5].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_17: {
                                        ...form.qid_17,
                                        sub_5: value,
                                        total: sumAll([
                                          form.qid_17.sub_1,
                                          form.qid_17.sub_2,
                                          form.qid_17.sub_3,
                                          form.qid_17.sub_4,
                                          form.qid_17.sub_5,
                                          value,
                                          form.qid_17.sub_7,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[4].subs[6].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_17: {
                                        ...form.qid_17,
                                        sub_5: value,
                                        total: sumAll([
                                          form.qid_17.sub_1,
                                          form.qid_17.sub_2,
                                          form.qid_17.sub_3,
                                          form.qid_17.sub_4,
                                          form.qid_17.sub_5,
                                          form.qid_17.sub_6,
                                          value,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsC[5].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsC[5].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsC[5].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              <S.Tooltip
                                title={
                                  establishedForm[2].phase_tooltip[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              >
                                <InfoCircleOutlined />
                              </S.Tooltip>
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  establishedForm[2].phase_total[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  value={
                                    form.qid_18.total !== 0
                                      ? form.qid_18.total
                                      : ''
                                  }
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_18: { ...form.qid_18, total: value },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                            <S.Divider>
                              <Col md={4}>
                                <S.DividerLine />
                              </Col>
                              <Col md={14} offset={1}>
                                <S.PhaseHintText type="secondary">
                                  {
                                    establishedForm[2].phase_hint[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                </S.PhaseHintText>
                              </Col>
                              <Col md={4} offset={1}>
                                <S.DividerLine />
                              </Col>
                            </S.Divider>
                            <S.Field>
                              <Text>
                                {
                                  questionsC[5].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_18: {
                                        ...form.qid_18,
                                        sub_1: value,
                                        total: sumAll([
                                          value,
                                          form.qid_18.sub_2,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[5].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_18: {
                                        ...form.qid_18,
                                        sub_2: value,
                                        total: sumAll([
                                          form.qid_18.sub_1,
                                          value,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsC[6].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsC[6].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsC[6].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              <S.Tooltip
                                title={
                                  establishedForm[2].phase_tooltip[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              >
                                <InfoCircleOutlined />
                              </S.Tooltip>
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  establishedForm[2].phase_total[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  value={
                                    form.qid_19.total !== 0
                                      ? form.qid_19.total
                                      : ''
                                  }
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_19: { ...form.qid_19, total: value },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                            <S.Divider>
                              <Col md={4}>
                                <S.DividerLine />
                              </Col>
                              <Col md={14} offset={1}>
                                <S.PhaseHintText type="secondary">
                                  {
                                    establishedForm[2].phase_hint[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                </S.PhaseHintText>
                              </Col>
                              <Col md={4} offset={1}>
                                <S.DividerLine />
                              </Col>
                            </S.Divider>
                            <S.Field>
                              <Text>
                                {
                                  questionsC[6].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_19: {
                                        ...form.qid_19,
                                        sub_1: value,
                                        total: sumAll([
                                          value,
                                          form.qid_19.sub_2,
                                          form.qid_19.sub_3,
                                          form.qid_19.sub_4,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[6].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_19: {
                                        ...form.qid_19,
                                        sub_2: value,
                                        total: sumAll([
                                          form.qid_19.sub_1,
                                          value,
                                          form.qid_19.sub_3,
                                          form.qid_19.sub_4,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[6].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_19: {
                                        ...form.qid_19,
                                        sub_3: value,
                                        total: sumAll([
                                          form.qid_19.sub_1,
                                          form.qid_19.sub_2,
                                          value,
                                          form.qid_19.sub_4,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[6].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_19: {
                                        ...form.qid_19,
                                        sub_4: value,
                                        total: sumAll([
                                          form.qid_19.sub_1,
                                          form.qid_19.sub_2,
                                          form.qid_19.sub_3,
                                          value,
                                        ]),
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsC[7].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsC[7].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsC[7].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsC[7].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsC[7].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  questionsC[7].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_20: {
                                        ...form.qid_20,
                                        sub_0: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[7].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_20: {
                                        ...form.qid_20,
                                        sub_1: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[7].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_20: {
                                        ...form.qid_20,
                                        sub_2: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[7].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_20: {
                                        ...form.qid_20,
                                        sub_3: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[7].subs[4].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_20: {
                                        ...form.qid_20,
                                        sub_4: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsC[7].subs[5].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_20: {
                                        ...form.qid_20,
                                        sub_5: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsC[8].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsC[8].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsC[8].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsC[8].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsC[8].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  establishedForm[2].phase_total[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  value={
                                    form.qid_21.total !== 0
                                      ? form.qid_21.total
                                      : ''
                                  }
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_21: { ...form.qid_21, total: value },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            {/* block D */}
            {step === 3 && (
              <div>
                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsD[0].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsD[0].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsD[0].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsD[0].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsD[0].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  questionsD[0].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_22: {
                                        ...form.qid_22,
                                        sub_1: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[0].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_22: {
                                        ...form.qid_22,
                                        sub_2: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[0].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_22: {
                                        ...form.qid_22,
                                        sub_3: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[0].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_22: {
                                        ...form.qid_22,
                                        sub_4: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[0].subs[4].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_22: {
                                        ...form.qid_22,
                                        sub_5: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsD[1].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsD[1].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsD[1].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                                {questionsD[1].tooltip[
                                  i18n.language === 'cs' ? 0 : 1
                                ] && (
                                  <S.Tooltip
                                    title={
                                      questionsD[1].tooltip[
                                        i18n.language === 'cs' ? 0 : 1
                                      ]
                                    }
                                  >
                                    <InfoCircleOutlined />
                                  </S.Tooltip>
                                )}
                              </S.QuestionDescription>
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  questionsD[1].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_23: {
                                        ...form.qid_23,
                                        sub_0: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[1].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_23: {
                                        ...form.qid_23,
                                        sub_1: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[1].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_23: {
                                        ...form.qid_23,
                                        sub_2: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[1].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_23: {
                                        ...form.qid_23,
                                        sub_3: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[1].subs[4].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_23: {
                                        ...form.qid_23,
                                        sub_4: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[1].subs[5].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_23: {
                                        ...form.qid_23,
                                        sub_5: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsD[2].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsD[2].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsD[2].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsD[2].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsD[2].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  questionsD[2].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_24: {
                                        ...form.qid_24,
                                        sub_1: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[2].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_24: {
                                        ...form.qid_24,
                                        sub_2: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[2].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_24: {
                                        ...form.qid_24,
                                        sub_3: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[2].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_24: {
                                        ...form.qid_24,
                                        sub_4: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[2].subs[4].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_24: {
                                        ...form.qid_24,
                                        sub_5: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item name={`qid_${questionsD[3].qid}`}>
                      <S.Question>
                        <S.QuestionNumber strong>
                          {questionsD[3].qid}.
                        </S.QuestionNumber>
                        <S.QuestionContent>
                          <S.QuestionHeader>
                            <S.QuestionTitle>
                              <S.QuestionDescription strong>
                                {
                                  questionsD[3].description[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </S.QuestionDescription>
                              {questionsD[3].tooltip[
                                i18n.language === 'cs' ? 0 : 1
                              ] && (
                                <S.Tooltip
                                  title={
                                    questionsD[3].tooltip[
                                      i18n.language === 'cs' ? 0 : 1
                                    ]
                                  }
                                >
                                  <InfoCircleOutlined />
                                </S.Tooltip>
                              )}
                            </S.QuestionTitle>
                          </S.QuestionHeader>
                          <S.QuestionAnswers>
                            <S.Field>
                              <Text>
                                {
                                  questionsD[3].subs[0].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_25: {
                                        ...form.qid_25,
                                        sub_1: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[3].subs[1].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_25: {
                                        ...form.qid_25,
                                        sub_2: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[3].subs[2].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_25: {
                                        ...form.qid_25,
                                        sub_3: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[3].subs[3].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_25: {
                                        ...form.qid_25,
                                        sub_4: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>

                            <S.Field>
                              <Text>
                                {
                                  questionsD[3].subs[4].text[
                                    i18n.language === 'cs' ? 0 : 1
                                  ]
                                }
                              </Text>
                              <S.InputWithCurrency>
                                <S.InputNumber
                                  min={0}
                                  placeholder={0}
                                  onChange={value =>
                                    setForm({
                                      ...form,
                                      qid_25: {
                                        ...form.qid_25,
                                        sub_5: value,
                                      },
                                    })
                                  }
                                />
                                <CurrencyLabel currency={currency} />
                              </S.InputWithCurrency>
                            </S.Field>
                          </S.QuestionAnswers>
                        </S.QuestionContent>
                      </S.Question>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            {step === 4 && (
              <Row>
                <Col span={20} offset={2}>
                  <S.Blockquote>{t('common.forms.final-message')}</S.Blockquote>
                </Col>
              </Row>
            )}

            <S.ButtonNav>
              <S.PrevButton
                size="large"
                step={step}
                onClick={() => {
                  setStep(step > 0 ? step - 1 : 0);
                  // window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                {t('common.buttons.previous')}
              </S.PrevButton>
              {step !== 4 ? (
                <S.NextButton
                  size="large"
                  type="primary"
                  onClick={() => {
                    setStep(step < 4 ? step + 1 : 4);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                >
                  {t('common.buttons.next')}
                </S.NextButton>
              ) : (
                <Button size="large" type="primary" htmlType="submit">
                  {t('common.buttons.submit')}
                </Button>
              )}
            </S.ButtonNav>
          </S.Form>
        </Content>
      </Container>
    </Layout>
  );
};

export default EstablishedSurvey;

const S = {};

S.TitleContainer = styled(Row)`
  padding: 0 60px;
  text-align: center;
  margin-bottom: 25px;
`;

S.Title = styled(Title)`
  && {
    font-weight: 900;
  }
`;

S.SubTitle = styled(Title)`
  && {
    font-weight: 700;
    margin-top: -20px;
    color: #666;
  }
`;

S.Paragraph = styled(Text)`
  && {
    line-height: 1.5;
    font-size: 16px;
    margin: 24px 0 0;
  }
`;

S.Blockquote = styled.div`
  text-align: center;
  justify-content: center;
  align-content: center;
  margin: 40px 0 !important;
  font-size: 18px;
  font-weight: 300;
`;

S.Question = styled.div`
  display: flex;
  flex-direction: row;
`;

S.QuestionHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

S.QuestionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

S.QuestionNumber = styled(Text)`
  margin: 0 15px 0;
`;

S.QuestionDescription = styled(Text)`
  margin: 0 15px;
`;

S.Tooltip = styled(Tooltip)``;

S.QuestionTitle = styled.div``;

S.QuestionAnswers = styled.div`
  margin: 15px;
`;

S.ButtonNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

S.PrevButton = styled(Button)`
  opacity: ${props => (props.step !== 0 ? 1 : 0)};
`;
S.NextButton = styled(Button)``;

S.Form = styled(Form)`
  margin: 0 60px;
`;

S.StepperBar = styled.div`
  margin: 0 60px 25px;
`;

S.PhaseDescription = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 25px;
  margin: 25px 0;
`;

S.InputNumber = styled(InputNumber)`
  && {
    width: 300px;
  }
`;

S.Divider = styled(Row)`
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px 0 20px;
`;

S.DividerLine = styled(Divider)`
  border: 1px solid #ccc;
`;

S.PhaseHintText = styled(Text)`
  font-size: 12px;
`;

S.Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

S.InputWithCurrency = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

S.CurrencyLabel = styled(Text)`
  margin-left: 15px;
  font-size: 16px;
`;

S.CurrencyChange = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 25px;
`;

S.CurrencyChangeLabel = styled(Text)`
  margin-right: 10px;
`;

S.InfoBar = styled(Row)`
  margin: 0 60px 25px;
`;

S.InfoBarText = styled(Text)`
  && {
    font-size: 14px;
    color: #888;
    margin: 0 25px 15px;
  }
`;

S.InfoBarMessages = styled.div`
  display: flex;
  flex-direction: column;
`;
