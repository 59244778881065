import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import AuthContext from '../../state/AuthContext';
import medcalLogo from '../../assets/images/logo.png';
import { Menu, Dropdown, Button, Typography } from 'antd';
import { UserOutlined, FlagOutlined } from '@ant-design/icons';
import StorageHandler, { STORAGE_USER } from '../../utils/storage.handler';
import './style.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const { Text } = Typography;

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const Auth = useContext(AuthContext);
  const history = useHistory();
  const [menuState, setMenuState] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loadUser = async () => {
      const u = await StorageHandler.parse(STORAGE_USER);
      setUser(u);
    };
    loadUser();
  }, []);

  const logout = async () => {
    Auth.setAuth(false);
    StorageHandler.clear();
    history.replace('/login');
  };

  const handleLangChange = () => {
    i18n.changeLanguage(i18n.language === 'cs' ? 'en' : 'cs');
  };

  const menu = () => {
    return (
      <Menu>
        {!Auth.auth && (
          <>
            <Menu.Item>
              <Link to="/login">{t('common.menu.signin')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/register">{t('common.menu.signup')}</Link>
            </Menu.Item>
          </>
        )}
        {Auth.auth && user && (
          <>
            <Menu.Item>
              <Link to="/profile">{user.fullName}</Link>
            </Menu.Item>
            <Menu.Item>
              <S.MenuButtonText onClick={logout}>
                {t('common.menu.signout')}
              </S.MenuButtonText>
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  return (
    <div className="Nav">
      <div className="logo">
        <img src={medcalLogo} alt="medical calculator logo" />
        <h1>medcal</h1>
      </div>
      <ul className={menuState ? 'toggle' : ''}>
        <li>
          <NavLink exact to="/" activeClassName="link-active">
            {t('common.menu.introduction')}
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/about-project" activeClassName="link-active">
            {t('common.menu.about-project')}
          </NavLink>
        </li>
        {/* <li>
          <NavLink exact to="/calculation-basis" activeClassName="link-active">
            {t('common.menu.calculation-basis')}
          </NavLink>
        </li> */}
        {Auth.auth && (
          <>
            <li>
              <NavLink exact to="/calculations" activeClassName="link-active">
                {t('common.menu.calculation')}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/calculations/filled"
                activeClassName="link-active"
              >
                {t('common.menu.filled-calculations')}
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink exact to="/contact" activeClassName="link-active">
            {t('common.menu.contact')}
          </NavLink>
        </li>
        {menuState && (
          <>
            {!Auth.auth ? (
              <>
                <li>
                  <Link to="/login">{t('common.menu.signin')}</Link>
                </li>
                <li>
                  <Link to="/register">{t('common.menu.signup')}</Link>
                </li>
              </>
            ) : (
              <li onClick={logout}>{t('common.menu.signout')}</li>
            )}
          </>
        )}

        <li className="close" onClick={() => setMenuState(false)}>
          X
        </li>
      </ul>
      <S.LangButton
        shape="round"
        icon={<FlagOutlined />}
        size="large"
        onClick={handleLangChange}
      >
        {i18n.language === 'cs' ? 'English' : 'Česky'}
      </S.LangButton>
      <Dropdown className="auth" overlay={menu} placement="bottomCenter">
        <Button shape="circle" size="large" icon={<UserOutlined />} />
      </Dropdown>
      <div className="menu" onClick={() => setMenuState(!menuState)}>
        Menu
      </div>
    </div>
  );
};

export default NavBar;

const S = {};

S.LangButton = styled(Button)`
  margin: 0 10px;
`;

S.MenuButtonText = styled(Text)`
  padding: 5 12px;
  font-size: 14px;
  color: #444;
  line-height: 22px;
  cursor: pointer;
  font-weight: normal;
  white-space: no-pre-wrap;
  margin: 0;
`;
