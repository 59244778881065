import axios from 'axios';

import StorageHandler, { ACCESS_TOKEN, REFRESH_TOKEN } from './storage.handler';

const instance = axios.create({
  baseURL: 'https://medicalcalculator.uhk.cz:3000/medcal/api/v1',
  // baseURL: 'http://localhost:3333/medcal/api/v1',
  timeout: 20000,
});

instance.interceptors.request.use(
  async config => {
    const accessToken = await StorageHandler.load(ACCESS_TOKEN);
    if (accessToken) {
      config.headers.common = { Authorization: `Bearer ${accessToken}` };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    const refreshToken = await StorageHandler.load(REFRESH_TOKEN);
    if (
      refreshToken &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      const res = await instance.get('/auth/refresh-token', {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          isRefreshToken: true,
        },
      });
      if (res.status === 200) {
        const { accessToken, refreshToken } = res.data.payload.data;
        await StorageHandler.save(ACCESS_TOKEN, accessToken);
        await StorageHandler.save(REFRESH_TOKEN, refreshToken);
        axios(originalRequest);
      }
    }
    return Promise.reject(error);
  },
);

const authHeader = (token, lang) => {
  return {
    Language: lang,
  };
};

const httpGet = (url, auth = false, token = null, lang = 'cs') => {
  return instance.get(url, auth ? { headers: authHeader(token, lang) } : null);
};

const httpPost = (url, data, auth = false, token = null, lang = 'cs') => {
  console.log({ url });
  return instance.post(
    url,
    data,
    auth
      ? { headers: authHeader(token, lang) }
      : { headers: { Language: lang } },
  );
};

const httpPdf = (url, data, token, lang = 'cs') => {
  return instance.post(url, data, {
    responseType: 'blob',
    headers: authHeader(token, lang),
  });
};

const httpPut = (url, data, auth = false, token = null, lang = 'cs') => {
  return instance.put(
    url,
    data,
    auth ? { headers: authHeader(token, lang) } : null,
  );
};

const httpDelete = (url, auth = false, token = null, lang = 'cs') => {
  return instance.delete(
    url,
    auth ? { headers: authHeader(token, lang) } : null,
  );
};

export default {
  httpGet,
  httpPost,
  httpPut,
  httpDelete,
  httpPdf,
};
