import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import StorageHandler, { REFRESH_TOKEN } from '../../utils/storage.handler';
import jwtDecode from 'jwt-decode';

export default function ProtectedRoute({
  auth: Auth,
  component: Component,
  history,
  ...rest
}) {
  history.listen(async () => {
    const refreshToken = await StorageHandler.load(REFRESH_TOKEN);
    if (refreshToken) {
      const decodedToken = jwtDecode(refreshToken);
      if (decodedToken.exp < Date.now() / 1000) {
        StorageHandler.clear();
        Auth.setAuth(false);
        return <Route {...rest} render={() => <Redirect to="/login" />} />;
      }
    }
  });

  return (
    <Route
      {...rest}
      render={() => (Auth.auth ? <Component /> : <Redirect to="/login" />)}
    />
  );
}
