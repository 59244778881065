const blockA = {
  questions: [
    {
      qid: 1,
      type: 0,
      description: [
        'Uveďte počet zaměstnanců ve firmě',
        'State the number of employees in the company',
      ],
      tooltip: ['', ''],
      placeholder: ['', ''],
      answers: [
        {
          id: 1,
          value: 1,
          text: ['do 10 zaměstnanců', 'less than 10 employees'],
        },
        {
          id: 2,
          value: 2,
          text: ['do 50 zaměstnanců', 'less than 50 employees'],
        },
        {
          id: 3,
          value: 3,
          text: ['do 250 zaměstnanců', 'less than 250 employees'],
        },
        {
          id: 4,
          value: 3,
          text: ['nad 250 zaměstnanců', 'more than 250 employees'],
        },
      ],
    },
    {
      qid: 2,
      type: 0,
      description: [
        'Jaký typ zdravotního prostředku plánujete vyvíjet a vyrábět?',
        'What type of medical device do you plan to develop and produce?',
      ],
      tooltip: [
        'Uveďte typ zdravotního prostředku, pro nějž chcete modelovat kalkulaci investiční návratnosti.',
        'State the type of the medical device for which you want to model an investment return calculation.',
      ],
      placeholder: ['', ''],
      answers: [
        {
          id: 1,
          value: 0,
          text: ['I', 'I'],
        },
        {
          id: 2,
          value: 0,
          text: ['Is', 'Is'],
        },
        {
          id: 3,
          value: 0,
          text: ['Im', 'Im'],
        },
        {
          id: 4,
          value: 0,
          text: ['IIa', 'IIa'],
        },
        {
          id: 5,
          value: 0,
          text: ['IIb', 'IIb'],
        },
        {
          id: 6,
          value: 0,
          text: ['III', 'III'],
        },
        {
          id: 7,
          value: 0,
          text: ['žádný', 'none'],
        },
      ],
    },
    {
      qid: 3,
      type: 0,
      description: ['Je řešení inovativní?', 'Is the solution innovative?'],
      tooltip: ['', ''],
      placeholder: ['', ''],
      answers: [
        {
          id: 1,
          value: 3,
          text: ['Ano', 'Yes'],
        },
        {
          id: 2,
          value: 1,
          text: ['Ne', 'No'],
        },
      ],
    },
    {
      qid: 4,
      type: 0,
      description: [
        'Plánujete IP řešení – patent?',
        'Do you plan an intellectual property solution – patent?',
      ],
      tooltip: ['', ''],
      placeholder: ['', ''],
      answers: [
        {
          id: 1,
          value: 2,
          text: ['Ano', 'Yes'],
        },
        {
          id: 2,
          value: 2,
          text: ['Ne', 'No'],
        },
        {
          id: 3,
          value: 3,
          text: ['Máme', 'Already have'],
        },
      ],
    },
    {
      qid: 5,
      type: 0,
      description: [
        'Je výrobek prokazatelně účinnější než vašeho konkurenta?',
        'Is the product demonstrably more effective than that of your competitor?',
      ],
      tooltip: ['', ''],
      placeholder: ['', ''],
      answers: [
        {
          id: 1,
          value: 3,
          text: ['Ano', 'Yes'],
        },
        {
          id: 2,
          value: 2,
          text: ['Ne', 'No'],
        },
        {
          id: 3,
          value: 1,
          text: ['Nemáme klinickou evidenci', 'No clinical evidence'],
        },
      ],
    },
    {
      qid: 6,
      type: 0,
      description: ['Jaký je bussines model?', 'What is your business model?'],
      tooltip: ['', ''],
      placeholder: ['', ''],
      answers: [
        {
          id: 1,
          value: 2,
          text: ['Samoplátci', 'Privately owned'],
        },
        {
          id: 2,
          value: 3,
          text: ['Veřejné zdravotnictví', 'Public healthcare'],
        },
      ],
    },
    {
      qid: 7,
      type: 1,
      description: [
        'Na jaký primární trh cílíte?',
        'Which primary market do you target?',
      ],
      tooltip: ['', ''],
      placeholder: ['', ''],
      answers: [
        {
          id: 1,
          value: 3,
          text: ['Česká republika', 'Czech Republic'],
        },
        {
          id: 2,
          value: 3,
          text: ['Západní Evropa', 'Western Europe'],
        },
        {
          id: 3,
          value: 3,
          text: ['Východní Evropa', 'Eastern Europe'],
        },
        {
          id: 4,
          value: 3,
          text: ['Ostatní země akceptující CE', 'Other countries accepting CE'],
        },
      ],
    },
    {
      qid: 8,
      type: 2,
      description: [
        'Jaké procento podílu na trhu zaujímáte?',
        'What is your market share percentage?',
      ],
      tooltip: ['číslo v %', 'number in %'],
      placeholder: ['podíl na trhu v procentech', 'Market share in percent '],
      answers: [],
    },
    {
      qid: 9,
      type: 0,
      description: [
        'Jaké procento trhu zabírá největší konkurent?',
        'What is the market share percentage of your main competitor?',
      ],
      tooltip: ['', ''],
      placeholder: ['', ''],
      answers: [
        {
          id: 1,
          value: 3,
          text: ['do 10%', 'less than 10%'],
        },
        {
          id: 2,
          value: 2,
          text: ['10%-30 %', '10%–30%'],
        },
        {
          id: 3,
          value: 1,
          text: ['30%-50%', '30%–50%'],
        },
        {
          id: 4,
          value: 1,
          text: ['nad 50%', 'more than 50%'],
        },
      ],
    },
  ],
};

const blockB = {
  phase_tooltip: [
    'Uveďte náklady na danou fázi vývoje zdravotních prostředků. Můžete uvést údaje v podrobném členění ve vazbě na odpracované hodiny, nebo jednou hodnotou v {#} odpovídající nákladům celé fáze vývoje.',
    'Indicate the costs for the given stage of medical device development. You can provide data in a detailed breakdown in relation to hours worked or a single value in {#} corresponding to the costs of the entire development phase.',
  ],
  phase_total: ['Celkový odhad nákladu:', 'Total cost estimate:'],
  phase_hint: [
    'Výše prosím zadejte odhad nákladů na danou fázi vývoje zdravotních prostředků, ve které předpokládáme, že probíhají některé z níže uvedených aktivit. Pokud chcete uvést přesnější odhad nákladů lze je specifikovat v rámci dílčí úkonů v této fázi (součet nákladů za jednotlivé položky se potom zobrazí u názvu dané fáze).',
    'Please provide a cost estimate above for the given stage of medical device development in which we assume that some of the activities listed below are taking place. If you want to provide a more accurate cost estimate, you can specify costs for the individual sub-tasks in this phase (the sum of costs for the individual items is then displayed next to the name of the phase).',
  ],
  questions: [
    {
      qid: 10,
      type: 3,
      description: ['Iniciační fáze', 'Initiation phase'],
      tooltip: ['', ''],
      subs: [
        {
          text: ['Zjištění potřeb zákazníků:', 'Identifying customer needs:'],
        },
        {
          text: [
            'Popis funkčnosti a výhod oproti stávajícím řešení, rozhodnutí o typu zdravotního prostředku:',
            'Description of functionality and advantages over the existing solution, decision on the type of medical device:',
          ],
        },
        {
          text: [
            'Ověření vyrobitelnosti:',
            'Verification of manufacturability:',
          ],
        },
        {
          text: ['Odhad nákladů a výnosů:', 'Estimated costs and revenues:'],
        },
        {
          text: [
            'Ostatní náklady spojené s iniciací prvotního nápadu na nový produkt:',
            'Other costs associated with the initiation of the idea for a new product:',
          ],
        },
        {
          text: [
            'Celkové ostatní režijní/nepřímé náklady za iniciační fázi:',
            'Total other overhead / indirect costs for the initiation phase:',
          ],
        },
      ],
    },
    {
      qid: 11,
      type: 3,
      description: ['Návrh konceptu', 'Concept design'],
      tooltip: ['', ''],
      subs: [
        {
          text: [
            'Specifikace strategie a plánu vývoje, analýza trhu a zhodnocení potenciálu produktu, odhad času potřebného na vývoj, zajištění personálního zabezpečení:',
            'Specification of development strategy and plan, market analysis and evaluation of product potential, estimation of time needed for development, provision of personnel:',
          ],
        },
        {
          text: [
            'Určení charakteristik produktu (funkce produktu, požadavky na materiál atd.):',
            'Determination of product characteristics (product function, requirements on materials, etc.):',
          ],
        },
        {
          text: [
            'Náklady na specifikaci výroby:',
            'Production specification costs:',
          ],
        },
        {
          text: [
            'Náklady na rozhodnutí o třídě MP a odhad souvisejících právních a legislativních úkonů:',
            'Costs for specification of the medical device class and estimation of related legal actions:',
          ],
        },
        {
          text: [
            'Patentová rešerše k návrhu konceptu:',
            'Patent review for concept design:',
          ],
        },
      ],
    },
    {
      qid: 12,
      type: 3,
      description: ['Návrh a vývoj', 'Design and development'],
      subs: [
        {
          text: [
            'Zpřesnění strategie a plánu vývoje, nákladů a výnosů, obchodní plán:',
            'Specification of development strategy and plan, costs and revenues, business plan:',
          ],
        },
        {
          text: [
            'Podrobný plán výroby včetně určení materiálů:',
            'Detailed production plan including specification of materials:',
          ],
        },
        {
          text: [
            'Odhad celkových nákladů na testování a hodnocení ve vazbě na uvažovaný typ zdravotního prostředku (PMFC, klinické zkoušky, analýza rizika aFMEA, dFMEA, tvorba doprovodné dokumentace atd.):',
            'Estimation of the total costs of testing and evaluation in relation to the type of the medical device considered (post-market clinical follow-up, clinical trials, AFMEA and DFMEA risk analysis, creating product documentation, etc.):',
          ],
        },
      ],
    },
    {
      qid: 13,
      type: 3,
      description: ['Ověření a zhodnocení', 'Verification and evaluation'],
      tooltip: ['', ''],
      subs: [
        {
          text: [
            'Validace a certifikace designu:',
            'Design validation and certification:',
          ],
        },
        {
          text: [
            'Náklady na testování nezahrnuté výše (biologické hodnocení, elektrická bezpečnost, eventuálně další zkoušky ve vztahu k povaze výrobku):',
            'Testing costs not included above (biological evaluation, electrical safety, or other tests in relation to the nature of the product):',
          ],
        },
        {
          text: [
            'Klinické hodnocení/zkouška/studie:',
            'Clinical evaluation / trial / study:',
          ],
        },
        {
          text: ['Tvorba technické složky:', 'Technical components:'],
        },
      ],
    },
    {
      qid: 14,
      type: 3,
      description: ['Výroba', 'Production'],
      tooltip: ['', ''],
      subs: [
        {
          text: ['Materiál:', 'Materials:'],
        },
        {
          text: ['Práce týmu na výrobě:', 'The team’s work on the production:'],
        },
        {
          text: [
            'Finalizace aktivit ve vztahu k řízení rizika:',
            'Finalizing activities with respect to risk management:',
          ],
        },
        {
          text: [
            'Finalizace aktivit ve vztahu k designové validaci a verifikaci:',
            'Finalizing activities with respect to design validation and certification:',
          ],
        },
        {
          text: [
            'Rezervní fond, náklady spojené s pojištěním rizik produktu:',
            'Reserve fund, costs associated with product risk insurance:',
          ],
        },
        {
          text: ['Náklady na sériovou výrobu:', 'Mass production costs:'],
        },
        {
          text: ['Vlastní výrobní linka:', 'Own production line:'],
        },
      ],
    },
    {
      qid: 15,
      type: 3,
      description: ['Uvedení na trh', 'Product launch'],
      tooltip: ['', ''],
      subs: [
        {
          text: [
            'Patentová přihláška (Evropský patent/ Česká republika atd.):',
            'Patent application (European patent / Czech Republic, etc.):',
          ],
        },
        {
          text: [
            'Školení obchodních zástupců a lékařů',
            'Training sales representatives and doctors:',
          ],
        },
      ],
    },
    {
      qid: 16,
      type: 3,
      description: [
        'Parametry spojené s existencí produktu na trhu',
        'Parameters related to the presence of the product on the market',
      ],
      tooltip: ['', ''],
      subs: [
        {
          text: [
            'PMCF v době životnosti produktu:',
            'Post-market clinical follow-up for the lifetime of the product:',
          ],
        },
        {
          text: ['Post Market Surveillance:', 'Post-market surveillance:'],
        },
        {
          text: [
            'Vyhodnocování zpětné vazby, plánování a nastavení změn:',
            'Evaluating feedback, planning and implementing changes:',
          ],
        },
        {
          text: ['Servisní náklady:', 'Servicing costs:'],
        },
      ],
    },
    {
      qid: 17,
      type: 3,
      description: [
        'Odhad nákladů na posouzení shody',
        'Estimated conformity assessment costs',
      ],
      tooltip: [
        'Uveďte předpokládané náklady na posouzení shody v následujících 5 letech.',
        'State the estimated conformity assessment costs in the following five years.',
      ],
      subs: [
        {
          text: [
            'Náklady na posouzení shody před uvedením na trh:',
            'Pre-launch conformity assessment costs:',
          ],
        },
        {
          text: [
            'Náklady na posouzení shody v prvním roce prodeje:',
            'Conformity assessment costs in the first year after launch: ',
          ],
        },
        {
          text: [
            'Náklady na posouzení shody ve druhém roce prodeje:',
            'Conformity assessment costs in the second year after launch:',
          ],
        },
        {
          text: [
            'Náklady na posouzení shody ve třetím roce prodeje:',
            'Conformity assessment costs in the third year after launch:',
          ],
        },
        {
          text: [
            'Náklady na posouzení shody ve čtvrtém roce prodeje:',
            'Conformity assessment costs in the fourth year after launch:',
          ],
        },
        {
          text: [
            'Náklady na posouzení shody v pátém roce prodeje:',
            'Conformity assessment costs in the fifth year after launch:',
          ],
        },
      ],
    },
    {
      qid: 18,
      type: 2,
      description: [
        'Celkový odhad nákladů na výrobu 1 ks MD',
        'Estimated total production costs for producing one piece of the medical device',
      ],
      tooltip: [
        'Uveďte náklady na výrobu 1 ks zdravotního prostředku (materiál, mzdové atd.).',
        'State the costs for producing one piece of the medical device (material costs, personnel costs, etc.).',
      ],
      placeholder: [
        'Odhad nákladů na výrobu 1 ks',
        'Estimated production costs per piece',
      ],
      subs: [],
    },
  ],
};

const blockC = {
  questions: [
    {
      qid: 19,
      type: 3,
      description: ['Počet prodaných kusů', 'Number of items sold '],
      tooltip: [
        'Uveďte předpokládané náklady na posouzení shody v následujících 5 letech.',
        'State the estimated conformity assessment costs in the following five years.',
      ],
      subs: [
        {
          text: [
            'Počet prodaných kusů za první rok:',
            'Number of items sold in the first year:',
          ],
        },
        {
          text: [
            'Počet prodaných kusů za druhý rok:',
            'Number of items sold in the second year:',
          ],
        },
        {
          text: [
            'Počet prodaných kusů za třetí rok:',
            'Number of items sold in the third year:',
          ],
        },
        {
          text: [
            'Počet prodaných kusů za čtvrtý rok:',
            'Number of items sold in the fourth year:',
          ],
        },
        {
          text: [
            'Počet prodaných kusů za pátý rok:',
            'Number of items sold in the fifth year:',
          ],
        },
      ],
    },
    {
      qid: 20,
      type: 3,
      description: ['Předpokládaná cena prodeje', 'Estimated selling price'],
      tooltip: ['', ''],
      subs: [
        {
          text: [
            'Předpokládaná cena prodeje za jeden kus:',
            'Estimated selling price per piece:',
          ],
        },
        {
          text: [
            'Předpokládaná cena prodeje za jeden kus za první rok:',
            'Estimated selling price per piece in the first year:',
          ],
        },
        {
          text: [
            'Předpokládaná cena prodeje za jeden kus za druhý rok:',
            'Estimated selling price per piece in the second year:',
          ],
        },
        {
          text: [
            'Předpokládaná cena prodeje za jeden kus za třetí rok:',
            'Estimated selling price per piece in the third year:',
          ],
        },
        {
          text: [
            'Předpokládaná cena prodeje za jeden kus za čtvrtý rok:',
            'Estimated selling price per piece in the fourth year:',
          ],
        },
        {
          text: [
            'Předpokládaná cena prodeje za jeden kus za pátý rok:',
            'Estimated selling price per piece in the fifth year:',
          ],
        },
      ],
    },
    {
      qid: 21,
      type: 3,
      description: [
        'Celkový příjem z licencí/patentů',
        'Total revenue from licenses / patents',
      ],
      tooltip: ['', ''],
      subs: [
        {
          text: [
            'Příjem z licencí/patentů za první rok:',
            'License / patent revenue in the first year:',
          ],
        },
        {
          text: [
            'Příjem z licencí/patentů za druhý rok:',
            'License / patent revenue in the second year:',
          ],
        },
        {
          text: [
            'Příjem z licencí/patentů za třetí rok:',
            'License / patent revenue in the third year:',
          ],
        },
        {
          text: [
            'Příjem z licencí/patentů za čtvrtý rok:',
            'License / patent revenue in the fourth year:',
          ],
        },
        {
          text: [
            'Příjem z licencí/patentů za pátý rok:',
            'License / patent revenue in the fifth year:',
          ],
        },
      ],
    },
    {
      qid: 22,
      type: 3,
      description: [
        'Celkový příjem ze servisních činností',
        'Total revenue from post-sale service ',
      ],
      tooltip: ['', ''],
      subs: [
        {
          text: [
            'Příjem ze servisních činností za první rok:',
            'Revenue from post-sale service in the first year:',
          ],
        },
        {
          text: [
            'Příjem ze servisních činností za druhý rok:',
            'Revenue from post-sale service in the second year:',
          ],
        },
        {
          text: [
            'Příjem ze servisních činností za třetí rok:',
            'Revenue from post-sale service in the third year:',
          ],
        },
        {
          text: [
            'Příjem ze servisních činností za čtvrtý rok:',
            'Revenue from post-sale service in the fourth year:',
          ],
        },
        {
          text: [
            'Příjem ze servisních činností za pátý rok:',
            'Revenue from post-sale service in the fifth year:',
          ],
        },
      ],
    },
  ],
};

export default [blockA, blockB, blockC];
