import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.less';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="Footer">
      {new Date().getFullYear()} © {t('contact.uhk')}
    </div>
  );
}
