import React, { useState } from 'react';
import Layout from '../../components/Layout';
import PartnersContainer from '../../components/PartnersContainer';
import { Row, Col, Typography, Form, Card, Button, Input } from 'antd';
import Container from '../../components/Container';
import styled from 'styled-components';
import apiReq from '../../utils/api.request';
import { useTranslation } from 'react-i18next';
import { EMAIL_AVAILABLE, RESET_LINK_SENT } from '../../utils/operation.status';

const { Title, Text } = Typography;

const ForgotPasswordPage = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [message, setMessage] = useState(null);
  const [code, setCode] = useState('red');

  const handleSubmit = async form => {
    const forgotPassReq = await apiReq.sendForgotPassword(
      { email: form.email },
      i18n.language,
    );
    const { meta } = forgotPassReq.data;
    setCode(meta.opsStatus === RESET_LINK_SENT ? 'green' : 'red');
    setMessage(
      meta.opsStatus === RESET_LINK_SENT
        ? t('password.link-sent')
        : t('password.non-existing-account'),
    );
  };

  const checkEmail = async () => {
    setMessage(null);
    const email = form.getFieldValue('email');
    if (email) {
      const emailCheckReq = await apiReq.checkEmailAvailability(email);
      const { meta: emailCheckReqMeta } = emailCheckReq.data;
      if (emailCheckReqMeta.opsStatus === EMAIL_AVAILABLE) {
        form.setFields([
          {
            name: 'email',
            errors: [t('password.non-existing-account')],
          },
        ]);
      }
    }
  };

  return (
    <Layout>
      <Container>
        <S.Row>
          <Col md={12} xs={24}>
            <S.Card>
              <S.Title level={2}>{t('common.menu.reset-password')}</S.Title>
              <Row justify="center">
                <S.Col>
                  <S.Description>
                    {t('password.forgot-password-description')}
                  </S.Description>
                </S.Col>
              </Row>
              <S.Form
                name="forgot"
                onFinish={handleSubmit}
                form={form}
                wrapperCol={{ span: 20, offset: 2 }}
                size="large"
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-login-email'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('common.forms.email')}
                    onBlur={checkEmail}
                  />
                </Form.Item>
                {message && <S.Message textcolor={code}>{message}</S.Message>}
                <S.FormFooter>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="reset-form-button"
                  >
                    {t('common.buttons.reset-link-send')}
                  </Button>
                </S.FormFooter>
              </S.Form>
            </S.Card>
          </Col>
        </S.Row>
      </Container>
      <PartnersContainer />
    </Layout>
  );
};

export default ForgotPasswordPage;

const S = {};

S.TitleContainer = styled.div`
  width: 60vw;
  margin: 0 auto;
  text-align: center;
`;

S.Title = styled(Title)`
  && {
    margin: 0 0 25px;
    font-weight: 900;
    text-align: center;
  }
`;

S.Row = styled(Row)`
  justify-content: center;
`;

S.Card = styled(Card)`
  border: 1px solid #ccc;
  border-radius: 5px;
`;

S.Form = styled(Form)`
  display: flex;
  flex-direction: column;
`;

S.FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.Col = styled(Col)`
  && {
    margin-bottom: 25px;
  }
`;

S.Description = styled(Text)`
  && {
    text-align: center;
  }
`;

S.Message = styled(Text)`
  && {
    text-align: center;
    margin: 15px 0;
    color: ${props => props.textcolor};
  }
`;
