import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import PartnersContainer from '../../components/PartnersContainer';
import { Row, Col, Typography, Form, Card, Button, Input } from 'antd';
import Container from '../../components/Container';
import styled from 'styled-components';
import apiReq from '../../utils/api.request';
import handleMessage from '../../utils/message.handler';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const useQuery = () => new URLSearchParams(useLocation().search);

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();
  const [code, setCode] = useState(null);

  const handleSubmit = async form => {
    const token = query.get('token');
    const resetPassReq = await apiReq.resetPassword(token, {
      password: form.password,
    });
    const { meta } = resetPassReq.data;
    setCode(meta.opsStatus);
    setTimeout(() => {
      history.replace('/login');
    }, 3000);
  };

  const checkPassword = async () => {
    const password = form.getFieldValue('password');
    if (password) {
      const isValid = password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g,
      );
      if (!isValid) {
        form.setFields([
          {
            name: 'password',
            errors: [t(`common.validation.password-validation`)],
          },
        ]);
      }
    }
  };

  return (
    <Layout>
      <Container>
        <S.Row>
          <Col md={12} xs={24}>
            <S.Card>
              <S.Title level={2}>{t('common.menu.reset-password')}</S.Title>
              <Row justify="center">
                <S.Col>
                  <S.Description>
                    {t('password.reset-password-description')}
                  </S.Description>
                </S.Col>
              </Row>
              <S.Form
                name="reset"
                onFinish={handleSubmit}
                form={form}
                layout="vertical"
                size="large"
              >
                <Form.Item
                  name="password"
                  label={t('common.forms.new-password')}
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-password'),
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password onBlur={checkPassword} />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label={t('common.forms.password-verify')}
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t('common.validation.fill-password-verify'),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          t('common.validation.passwords-wrong'),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                {code && (
                  <S.Message textcolor="green">
                    {t(`password.${handleMessage(code)}`)}
                  </S.Message>
                )}

                <S.FormFooter>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="password-change-form-button"
                  >
                    {t('common.buttons.password-change')}
                  </Button>
                </S.FormFooter>
              </S.Form>
            </S.Card>
          </Col>
        </S.Row>
      </Container>
      <PartnersContainer />
    </Layout>
  );
};

export default ResetPasswordPage;

const S = {};

S.TitleContainer = styled.div`
  width: 60vw;
  margin: 0 auto;
  text-align: center;
`;

S.Title = styled(Title)`
  && {
    margin: 0 0 25px;
    font-weight: 900;
    text-align: center;
  }
`;

S.SubTitle = styled(Title)`
  && {
    font-weight: 700;
    margin-top: -20px;
    color: #666;
  }
`;

S.Row = styled(Row)`
  justify-content: center;
`;

S.Card = styled(Card)`
  border: 1px solid #ccc;
  border-radius: 5px;
`;

S.AvatarImg = styled.img`
  height: 75px;
  width: 75px;
`;

S.Form = styled(Form)`
  display: flex;
  flex-direction: column;
`;

S.FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.Description = styled(Text)``;

S.Col = styled(Col)`
  && {
    margin-bottom: 25px;
  }
`;

S.Message = styled(Text)`
  && {
    text-align: center;
    margin: 15px 0;
    color: ${props => props.textcolor};
  }
`;
